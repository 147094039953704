import React from 'react'
import spree_coin from './../../images/icons/spreecoin.svg';
import stepper_img from './../../images/big/Group 89536.png'
import Countdown from 'react-countdown';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import back_icon from './../../images/icons/back arrow.svg';
import help_icon from './../../images/icons/help icon.svg';
import animation_icon from './../../images/icons/animation_500_lgdbznrx.gif';
import notification_icon from './../../images/icons/notifivation icon.svg';
import { useHistory } from "react-router-dom";
import { calculateTimeLeft } from '../../lib/counter';

function HeaderStock(props) {
    let userWalletInfo = useSelector((state) => state.auth?.auth?.wallet);
    const contestName = useSelector(state => state.contest.selected_contest.contest_title);
	let history = useHistory();
    const startTime = useSelector(state => state.contest.selected_contest.contests_start_time);
    let startDate = new Date(startTime)

    let timeLeftToStart = calculateTimeLeft(startTime);
  
      const renderer = ({ days, hours, minutes, seconds,props, completed }) => {
          if (completed) {
            // Render a completed state
            return <>You are good to go!</>;
          } else {
            // Render a countdown
            if (days) {
              return <span>{days} Days left </span>;
            } else {
              return (
                <span>
                  {hours.toString().padStart(2, "0")}:
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </span>
              );
            }
          }
        };
  



    return (
      <div className="header-wrapper-home contest-header-wrapper">
        <div className="header-bull">
          <div>
            <img
              src={back_icon}
              alt="back_icon"
              onClick={() => history.goBack()}
              className="back-icon"
            />
          </div>
          <div className="d-flex">
            <div className="position-relative">
              <Link to="fps-page">
                <img
                  src={animation_icon}
                  alt="wallet_icon"
                  className="animation_icon "
                />
                <img src={help_icon} alt="wallet_icon" className="faq_icon " />
              </Link>
            </div>
            <div>
              <Link to="notification-page">
                <img
                  src={notification_icon}
                  alt="wallet_icon"
                  className="explore_icon_center"
                />
              </Link>
            </div>
            <div>
              <Link to="wallet-page">
                <div className="total-coins">
                  <img
                    src={spree_coin}
                    alt="wallet_icon"
                    className="img-fluid sm-logo mt-1"
                  />
                  <span style={{ fontSize: "12px" }}>
                    {userWalletInfo.total_amount_coins.toFixed(2)}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="header-content header-content-padd">
          <h4>{contestName}</h4>
          <div className="d-flex-count">
            <span className='mr-1'>Starts in : </span>
            <div>
              <Countdown
                className="counter-contest"
                date={startDate}
                timeLeft={timeLeftToStart}
                renderer={renderer}
              />
            </div>
          </div>
        </div>
        <div className="pos-fix-fix">
          <img
            src={stepper_img}
            alt="wallet_icon"
            className="img-fluid sm-logo mt-1"
          />
        </div>
      </div>
    );
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(HeaderStock);