import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import back_icon from './../../images/icons/back.svg';
import right_icon from './../../images/icons/vuesax-linear-tick-circle.svg';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from "react-router-dom";

function AccountVerification(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [panNumber, setPanNumber] = useState('');
    const [upiId, setUpiId] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [reenterAccountNumber, setReenterAccountNumber] = useState('');
    const [name, setName] = useState('');
    const [showTickImg, setShowTickImg] = useState(false)
    const [showUPITickImg, setShowUPITickImg] = useState(false)
    const [showBankTickImg, setShowBankTickImg] = useState(false)
    const [showVerifyBtn, setShowVerifyBtn] = useState(true)
    const [showVerifyUPIBtn, setShowVerifyUPIBtn] = useState(true)
    const [showOnlyUPI, setShowOnlyUPI] = useState(true)
    const [disabled, setDisabled] = useState(false);
    const [disabledUPI, setDisabledUPI] = useState(false);

    let errorsObj = { pan: '', dob: '', upi: '', account_number: '', reenter_account_number: '', ifsc: '', name: '' };

    const [errors, setErrors] = useState(errorsObj);
    let history = useHistory();

    useEffect(() => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-kyc-details?kyc_type=PAN',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
        };
        axios(config)
            .then(function (response) {
                if (response.data.original_data.user_kyc !== null) {
                    setPanNumber(response.data.original_data.user_kyc.pan_number)
                    setShowTickImg(true)
                    setShowVerifyBtn(false)
                    setDisabled(true)

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-kyc-details?kyc_type=UPI',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
        };
        axios(config)
            .then(function (response) {
                if (response.data.original_data.user_kyc !== null) {
                    setUpiId(response.data.original_data.user_kyc.vpa_address)
                    setShowUPITickImg(true)
                    setShowVerifyUPIBtn(false)
                    setShowOnlyUPI(false)
                    setDisabledUPI(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-kyc-details?kyc_type=BANK',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
        };
        axios(config)
            .then(function (response) {
                if (response.data.original_data.user_kyc !== null) {
                    console.log("bank verified")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    const verifyUPIID = (upiId) => {
        var axios = require('axios');
        var data = {
            "vpa": upiId
        }

        var config = {
            method: 'post',
            url: 'https://api.bullspree.com/api/v1/upi-validate-details',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data.success));
                if (response.data.success === true) {
                    setShowUPITickImg(true)
                    setShowVerifyUPIBtn(false)
                    setDisabled(true)
                    setShowOnlyUPI(false)
                }
                let error = false;
                const errorObj = { ...errorsObj };
                if (response.data.success === false) {
                    errorObj.upi = response.data.message;
                    error = true;
                }
                setErrors(errorObj);
                if (error) {
                    return;
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const verifyPanClick = (panNumber) => {
        var axios = require('axios');
        var data = {
            "pan_number": panNumber
        }

        var config = {
            method: 'post',
            url: 'https://api.bullspree.com/api/v1/verify-pan',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data.success));
                if (response.data.success === true) {
                    setShowTickImg(true)
                    setShowVerifyBtn(false)
                    setDisabled(true)
                }
                let error = false;
                const errorObj = { ...errorsObj };
                if (response.data.success === false) {
                    errorObj.pan = response.data.message;
                    error = true;
                }
                setErrors(errorObj);
                if (error) {
                    return;
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const verifyBankDetail = (accountNumber, ifscCode, name) => {
        var axios = require('axios');
        var data = {
            "bank_account_number": accountNumber,
            "name": name,
            "bank_ifsc_code": ifscCode
        }

        var config = {
            method: 'post',
            url: 'https://api.bullspree.com/api/v1/verify-bank',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data.success));
                if (response.data.success === true) {
                    console.log("verified", response.data)
                }
                let error = false;
                const errorObj = { ...errorsObj };
                if (response.data.success === false) {
                    errorObj.name = response.data.message;
                    error = true;
                }
                setErrors(errorObj);
                if (error) {
                    return;
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const onVerifyPanClick = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (panNumber === '') {
            errorObj.pan = 'Please Enter PAN Number To Continue';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        verifyPanClick(panNumber)

    }
    const onVerifyUPIID = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (upiId === '') {
            errorObj.upi = 'Please Enter UPI ID To Continue';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        verifyUPIID(upiId)

    }
    const onVerifyBankDetail = (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (accountNumber === '') {
            errorObj.account_number = 'Please Enter Account Number To Continue';
            error = true;
        }
        if (reenterAccountNumber === '') {
            errorObj.reenter_account_number = 'Please Re Enter Account Number To Continue';
            error = true;
        }
        if (ifscCode === '') {
            errorObj.ifsc = 'Please Enter IFSC Code To Continue';
            error = true;
        }
        if (name === '') {
            errorObj.name = 'Please Enter Name To Continue';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        verifyBankDetail(accountNumber, ifscCode, name)

    }

    return (
        <>
            <div className='wrapper'>
                <div className='top-head-logo mb-25'>
                    <div>
                        <img
                            src={back_icon}
                            alt="back_icon"
                            onClick={() => history.goBack()}
                            className="back-icon"
                        />
                    </div>
                    <div>
                        <h4 className='m-0 noti-head'>KYC & Bank Details</h4>
                    </div>
                </div>
                <div className='tab-bull-page'>
                    <Tabs
                        defaultActiveKey="pan_card"
                        id="uncontrolled-tab-example"
                        className="bull-tabs"
                    >
                        <Tab className='tab-1' eventKey="pan_card" title="PAN CARD">
                            <div className='pan-detail-wrapper'>
                                <div className="form-group bull-form-group">
                                    <label>Pan Card Details</label>
                                    {showTickImg ?
                                        <img
                                            src={right_icon}
                                            alt="right_icon"
                                            className="right_icon ml-2"
                                        />
                                        : null}
                                    <input type="text" maxLength="10" disabled={disabled} placeholder='Enter PAN Card Number' className="form-control bull-form-control text-uppercase" value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                                    {errors.pan && <div className="text-danger fs-12">{errors.pan}</div>}
                                </div>
                                {/* <div className="form-group bull-form-group">
                                    <label>Date of Birth</label>
                                    <input type="text" maxLength="25" placeholder='YYYY-MM-DD' className="form-control bull-form-control" value={birtDate} onChange={(e) => setBirtDate(e.target.value)} />
                                    {errors.dob && <div className="text-danger fs-12">{errors.dob}</div>}
                                </div> */}
                                {showVerifyBtn ?
                                    <>
                                        <div>
                                            <button type="submit" className="login-btn-dark adjust-btn" onClick={onVerifyPanClick}>
                                                <h5>Verify PAN Card</h5>
                                            </button>
                                        </div>

                                        <p>The Verification process may take upto 24 hours from the time of submission</p>
                                    </>
                                    : null}
                            </div>
                        </Tab>
                        <Tab className='tab-1' eventKey="bank_detail" title="BANK DETAILS">
                            <div className='pan-detail-wrapper'>
                                <div className="form-group bull-form-group">
                                    <label>UPI ID</label>
                                    {showUPITickImg ?
                                        <img
                                            src={right_icon}
                                            alt="right_icon"
                                            className="right_icon ml-2"
                                        />
                                        : null}
                                    <input type="text" disabled={disabledUPI} placeholder='Enter UPI ID' className="form-control bull-form-control" value={upiId} onChange={(e) => setUpiId(e.target.value)} />
                                    {errors.upi && <div className="text-danger fs-12">{errors.upi}</div>}
                                </div>
                                {showVerifyUPIBtn ?
                                    <div>

                                        <button type="submit" className="login-btn-dark adjust-btn" onClick={onVerifyUPIID}>
                                            <h5>Verify UPI ID</h5>
                                        </button>
                                    </div>
                                    : null}
                            </div>
                            {showOnlyUPI ?
                                <>
                                    <div className='d-flex m-2'>
                                        <div className='or-div'></div>
                                        <span className='or-span'>Or</span>
                                        <div className='or-div'></div>
                                    </div>
                                    <div className='pan-detail-wrapper'>
                                        <div className="form-group bull-form-group">
                                            <label>Bank Details</label>
                                            {showBankTickImg ?
                                                <img
                                                    src={right_icon}
                                                    alt="right_icon"
                                                    className="right_icon ml-2"
                                                />
                                                : null}
                                            <div className='mb-4'>
                                                <input type="text" maxLength="25" placeholder='Enter Account Number' className="form-control bull-form-control" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                                {errors.account_number && <div className="text-danger fs-12">{errors.account_number}</div>}
                                            </div>
                                            <div className='mb-4'>
                                                <input type="text" maxLength="25" placeholder='Re-Enter Account Number' className="form-control bull-form-control" value={reenterAccountNumber} onChange={(e) => setReenterAccountNumber(e.target.value)} />
                                                {errors.reenter_account_number && <div className="text-danger fs-12">{errors.reenter_account_number}</div>}
                                            </div>
                                            <div className='mb-4'>
                                                <input type="text" maxLength="25" placeholder='IFSC Code' className="form-control bull-form-control text-uppercase" value={ifscCode} onChange={(e) => setIfscCode(e.target.value)} />
                                                {errors.ifsc && <div className="text-danger fs-12">{errors.ifsc}</div>}
                                            </div>
                                            <div className='mb-4'>
                                                <input type="text" maxLength="25" placeholder='Account Holder Name' className="form-control bull-form-control" value={name} onChange={(e) => setName(e.target.value)} />
                                                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="login-btn-dark adjust-btn" onClick={onVerifyBankDetail}>
                                                <h5>Verify Bank Account</h5>
                                            </button>
                                        </div>
                                        <p>PAN Card name and Bank Account must match to complete KYC</p>
                                    </div>
                                </>
                                : null}
                        </Tab>

                    </Tabs>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(AccountVerification);