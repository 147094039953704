import React from 'react'
import { connect } from 'react-redux';
import { Card, Tab, Nav } from 'react-bootstrap'
import UpcomingContest from './UpcomingContest';
import LiveContest from './LiveContest';
import CompletedContest from './CompletedContest';

function MyGamesTab(props) {
    const tabData = [
        {
          name: 'UPCOMING',
          content: <UpcomingContest/>
        },
        {
          name: 'LIVE',
          content:<LiveContest/> 
        },
        {
          name: 'COMPLETED',
          content:<CompletedContest/>
        }
      ]
    

    return (
        <>

            <Card>
            <Card.Body className='p-2 pt-4'>
              {/* <!-- Nav tabs --> */}
              <div className='custom-tab-1'>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as='ul' className='nav-tabs'>
                    {tabData.map((data, i) => (
                      <Nav.Item as='li' key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {tabData.map((data, i) => (
                      <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                        <div className='mygames-data-list-wrapper'>{data.content}</div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(MyGamesTab);