import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import back_icon from "./../../images/icons/back.svg";
import completed_trophy_icon from "./../../images/icons/trophy.png";
import spree_coin from "./../../images/icons/spreecoin.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useHistory } from "react-router-dom";
import stock_up_icon from "./../../images/icons/vuesax-linear-danger.svg";
import stock_down_icon from "./../../images/icons/vuesax-linear-danger-1.svg";
import axios from "axios";
import crown from "./../../images/icons/Crown.svg";
import { selectUserTeamAction } from "../../store/actions/ContestAction";

function ContestDetail(props) {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
  const [contestDetail, setContestDetail] = useState([]);
  const [listOfTeams, setListOfTeams] = useState([]);
  const [stockDetails, setStocksDetails] = useState([]);
  const [topRanks, setTopRanks] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [investedDetail, setInvestedDetail] = useState([]);
  const userTeamId = useSelector((state) => state.contest.user_team_id);

  const prevUserTeamId = useRef(null);
  let history = useHistory();

  const getUserContestDetails = async () => {
    let config = {
      method: "get",
      url: `https://api.bullspree.com/api/v1/user-contest-details-v2?team_id=${userTeamId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const response = await axios(config);
      setContestDetail(response?.data?.original_data?.user_team);
      setListOfTeams(
        response?.data?.original_data?.user_team?.user_team_relation
      );

      // Now call Beginners Contest team stock API
      getBeginnerContestTeamStocks(
        response?.data?.original_data?.user_team?.contests_id,
        userTeamId
      );
    } catch (err) {
      console.log(
        "There is error in calling API = user-contest-details-v2, ERROR MESSAGE :",
        err
      );
    }
  };

  const getBeginnerContestTeamStocks = async (contestId, userTeamId) => {
    let config2 = {
      method: "get",
      url: `https://api.bullspree.com/api/v1/get-beginner-contest-team-stocks?contest_id=${contestId}&team_id=${userTeamId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const response2 = await axios(config2);
      setStocksDetails(response2.data.data.teamStockList);
      setInvestedDetail(response2.data.data.totalCoins);
    } catch (err) {
      console.log(
        "There is error in calling API = get-beginner-contest-team-stocks, ERROR MESSAGE :",
        err
      );
    }
  };

  const getLeaderTopRanks = async () => {
    var config3 = {
      method: "get",
      url: `https://api.bullspree.com/api/v1/user-team-leader-board?team_id=${userTeamId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response3 = await axios(config3);
      console.log(response3.data, "test top data");
      setTopRanks(response3.data.original_data);
    } catch (err) {
      console.log(
        "There is error in calling API = get-beginner-contest-team-stocks, ERROR MESSAGE :",
        err
      );
    }
  };
  const getLeaderRanks = async () => {
    var config4 = {
      method: "get",
      url: `https://api.bullspree.com/api/v1/user-team-leader-board-pagination?page=1&team_id=${userTeamId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response4 = await axios(config4);
      console.log(response4.data, "test top data");
      setRanks(response4.data.original_data.other_ranks.data);
    } catch (err) {
      console.log(
        "There is error in calling API = get-beginner-contest-team-stocks, ERROR MESSAGE :",
        err
      );
    }
  };

  // Initial UseEffect
  useEffect(() => {
    getLeaderTopRanks();
    getLeaderRanks();
    if (userTeamId && prevUserTeamId.current !== userTeamId) {
      // Call user contest details API
      getUserContestDetails();
    }
    prevUserTeamId.current = userTeamId;
  }, [userTeamId]);

  const handleTeamSelection = (e, teamId) => {
    // change userTeamId if it is different then previously selected one
    if (prevUserTeamId.current !== teamId) dispatch(selectUserTeamAction(teamId));
  };

  let startDate = new Date(
    contestDetail?.contest_relation?.contests_start_time
  );
  const sdate = startDate.getDate();
  const smonth = startDate.toLocaleString("default", { month: "short" });
  const shours = startDate.getHours();
  const sminutes = startDate.getMinutes();

  const priceDetailClick = () => {
    console.log("i was clicked");
    history.push("/price-detail");
  };
  const Completionist = () => {
    return null;
  };
  return (
    <>
      <div className="wrapper inner-wrapper">
          <div className="contest-detail-fix-position">
          <div className="top-head-logo justify-content-between">
            <div className="d-flex justify-content-start">
              <div>
                <img
                  src={back_icon}
                  alt="back_icon"
                  onClick={() => history.goBack()}
                  className="back-icon"
                />
              </div>
              <div>
                <h4 className="m-0 noti-head">COMPLETED</h4>
              </div>
            </div>
            <div>
              <button
                onClick={() => priceDetailClick()}
                className="view-completed-btn-active"
              >
                Prize Details
              </button>
            </div>
          </div>
          <div className="d-flex mb-25 justify-content-start ml-3 mt-2 overflow-auto">
            {Array.isArray(listOfTeams) &&
              listOfTeams.map(function (d, i) {
                return (
                  <div key={i}>
                    <button
                      className={
                        d.id == userTeamId
                          ? "view-completed-btn-active detail-btn mr-2"
                          : "view-completed-btn detail-btn mr-2"
                      }
                      onClick={(e) => handleTeamSelection(e, d.id)}
                    >
                      {d?.unique_user_team}
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="contest-info-wrapper p-0">
            <div className="d-flex justify-content-between pmcd-adjust">
              <div>
                <img
                  src={completed_trophy_icon}
                  alt="completed_trophy_icon"
                  className="completed_trophy_icon"
                />
                <h5 className="completed-title">
                  {contestDetail?.contest_relation?.contest_title}
                </h5>
              </div>
              <h6 className="completed-team-name">
                {
                  contestDetail?.user_team_relation?.find(
                    (item) => item.id == userTeamId
                  ).unique_user_team
                }
              </h6>
            </div>
            <div className="d-flex justify-content-between pr-3 pl-3 pb-3">
              <div>
                <span className="d-block completed-team-data-heading">WON</span>
                <span className="d-block completed-team-data">
                  <img
                    src={spree_coin}
                    alt="wallet_icon"
                    className="smallest-spree"
                  />
                  {contestDetail?.won_coins}
                </span>
              </div>
              <div>
                <span className="d-block completed-team-data-heading">
                  Rank
                </span>
                <span className="d-block completed-team-data">
                  {" "}
                  {contestDetail?.my_rank}
                </span>
              </div>
              <div>
                <span className="d-block completed-team-data-heading">
                  Spots
                </span>
                <span className="d-block completed-team-data">
                  {contestDetail?.contest_relation?.join_spot}
                </span>
              </div>
              <div>
                <span className="d-block completed-team-data-heading">
                  Entry
                </span>
                <span className="d-block completed-team-data">
                <img
                                        src={spree_coin}
                                        className="mt-1"
                                        alt=""
                                    />
                  {contestDetail?.contest_relation?.entry_fees_coins}
                </span>
              </div>
            </div>
          </div>
          </div>
          <div className="tab-bull-page">
            <Tabs
              defaultActiveKey="stock-list"
              id="uncontrolled-tab-example"
              className="bull-tabs"
            >
              <Tab className="tab-1" eventKey="stock-list" title="STOCK LIST">
                <div className="completed-tab-wrapeer">
                  <div className="stock-list-head-wrapper">
                    <div>
                      <h3>MY PORTFOLIO</h3>
                    </div>
                    <div>
                      <h6>Amount Invested</h6>
                    </div>
                    <div>
                      <h6>Final Value</h6>
                    </div>
                  </div>
                  {Array.isArray(stockDetails) &&
                    stockDetails.map(function (d, i) {
                      return (
                        <div
                          style={{ marginBottom: "20px" }}
                          className="d-flex justify-content-between"
                          key={i}
                        >
                          <div className="d-flex justify-content-start w-50">
                            <div>
                              <img
                                src={d?.stockImage}
                                className="stock_icon_img"
                                alt=""
                              />
                            </div>
                            <div className="stock-name-wrapper">
                              <h3 className="text-white">{d?.stock}</h3>

                              <span>
                                <img
                                  src={ d?.percentage >= 0 ? stock_up_icon : stock_down_icon}
                                  alt="stock_icon"
                                  className="stock_change_img text-white"
                                />

                                <span className="stock-percentage-change text-white">
                                  {d?.percentage}
                                </span>
                                <span className="stock-current-price text-white">
                                  {d?.currentPrice}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between w-50">
                            <div>
                              <div className="coins-invested">
                                {d?.coinInvested}
                              </div>
                            </div>
                            <div>
                              <div className="coins-invested">
                                {d?.currentValue}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="detail-page-footer">
                  <div className="detail-ammount-invested">
                    Final Value = {investedDetail.totalCoinEarned}
                  </div>
                  <div className="detail-ammount-invested earned">
                    Invested = {investedDetail.totalCoinInvested}
                  </div>
                </div>
              </Tab>
              <Tab className="tab-2" eventKey="leaderboard" title="LEADERBOARD">
                <div className="leaderboard-completed-wrap">
                  <div className="leaderboard-wrapper">
                    {Object.entries(topRanks).map(([key, value], index) => {
                      if (
                        ["first_rank", "second_rank", "third_rank"].includes(
                          key
                        ) &&
                        value.length > 0
                      ) {
                        return (
                          <div className={`leader-top-rank ${key}`} key={index}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <img
                                  src={crown}
                                  alt="crown"
                                  className="crown"
                                />
                                <h5 className="completed-rank">
                                  {key === "first_rank"
                                    ? "First Prize"
                                    : key === "second_rank"
                                    ? "Second Prize"
                                    : "Third Prize"}
                                </h5>
                              </div>
                              <h6 className={`completed-prize ${key}`}>
                                <img
                                  src={spree_coin}
                                  alt="spree_coin"
                                  className="spree_coin"
                                />
                                {value?.[0]?.won_coins}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-start">
                              { value.length>4 ? value.slice(0,4).map((user, index) => (
                                <div className="winners-rank" key={index}>
                                  {user?.user_team_relation?.user?.full_name
                                    ?.charAt(0)
                                    .toUpperCase()}
                                </div>
                              )) : value.map((user, index) => (
                                <div className="winners-rank" key={index}>
                                  {user?.user_team_relation?.user?.full_name
                                    ?.charAt(0)
                                    .toUpperCase()}
                                </div>
                              )) }

                              {value.length > 4 ? (
                                <span className="more-winner">{`+${
                                  value.length - 4
                                } more`}</span>
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="upcoming-leader-wrapper justify-content-start align-items-start">
                    {/* Show user rank if exist after 3rd rank */}
                    {topRanks?.user_teams && topRanks?.user_teams.length > 0
                      ? topRanks?.user_teams.map((userDetails, index) => (
                        userDetails?.my_rank > 3 ? (
                          <div className="lead-wrap self-tile" key={index}>
                            <div className="rank-lead">
                              {userDetails?.my_rank}
                            </div>

                            <div className="name-lead">
                              <img
                                src={userDetails?.user_relation?.profile_pic}
                                alt="spree_coin"
                                className="smallest-spree"
                              />
                              <div className="user-name" style={{marginLeft:"5px"}}>
                              {userDetails?.user_relation?.full_name.substring(
                                0,
                                3
                              ) +
                                (userDetails?.user_relation?.full_name.trim()
                                  .length > 3
                                  ? "..."
                                  : "")}
                              </div>
                              
                            </div>
                            <div className="value-lead">
                              <div>Final Value</div>
                              <div className="name-lead">
                                {parseFloat(userDetails?.total_points).toFixed(
                                  2
                                )}
                              </div>
                            </div>
                            <div className="value-lead">
                              <div>You Won</div>
                              <div className="name-lead">
                                <img
                                  src={spree_coin}
                                  alt="spree_coin"
                                  className="smallest-spree"
                                />
                                {userDetails?.won_coins}
                              </div>
                            </div>
                          </div>
                        ):null
                          
                        ))
                      : null}

                    {Array.isArray(ranks) &&
                      ranks.map(function (d, i) {
                        return (
                          <div className="lead-wrap" key={i}>
                            <div className="rank-lead">{d?.my_rank}</div>

                            <div className="name-lead">
                              <img
                                src={d?.user_relation?.profile_pic}
                                alt="spree_coin"
                                className="smallest-spree"
                              />
                              <div className="user-name" style={{marginLeft:"5px"}}>
                              {d?.user_relation?.full_name.substring(0, 3) +
                                (d?.user_relation?.full_name.trim().length > 3
                                  ? "..."
                                  : "")}
                              </div>
                              
                            </div>
                            <div className="value-lead">
                              <div>Final Value</div>
                              <div className="name-lead">
                                {parseFloat(d?.total_points).toFixed(2)}
                              </div>
                            </div>
                            <div className="value-lead">
                              <div>You Won</div>
                              <div className="name-lead">
                                <img
                                  src={spree_coin}
                                  alt="spree_coin"
                                  className="smallest-spree"
                                />
                                {d?.won_coins}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ContestDetail);
