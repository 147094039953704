import { Fragment, React, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import Countdown from "react-countdown";
import iconic_trophy_icon from "./../../images/icons/Icon ionic-ios-trophy (1).svg";
import trophy_icon from "./../../images/icons/Icon metro-trophy (1).svg";
import guarantee_icon from "./../../images/icons/Group 92799.svg";
import { selectContestAction, selectExchangeTypeAction, selectUserTeamAction } from "../../store/actions/ContestAction";
import { useHistory, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

function GlobalLeaderBoard(props) {
  const [data, setData] = useState([]);
  const [noContest, setNoContest] = useState(true);
  const exchangeID = useSelector(state => state.contest.exchange_type);
  const dispatch = useDispatch();
  let history = useHistory();

  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
  useEffect(() => {
    let data = JSON.stringify({
      page: 1,
      game_type: "LEAGUE",
      contest_type: "UPCOMING",
      user_level: 1,
      exchange_type: exchangeID,
    });

    let config = {
      method: "post",
      url: "https://api.bullspree.com/api/v1/my-games-v2",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        // if (response.data.original_data.contests.pagination.total > 0) {
        setNoContest(response.data.original_data.contests.data.length === 0);
        setData(response.data.original_data.contests.data);
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [authToken, exchangeID]);

  const cardClick = (test) => {
    dispatch(selectUserTeamAction(test));
    history.push("/contest-detail-upcoming");
  };

  const addMore = (item) => {
    console.log("i was clicked", item, item?.contests_id);
    dispatch(selectContestAction(item.contest_relation));
    history.push("/page-contest-stocks");
  };
  const viewDetail = (test) => {
    dispatch(selectUserTeamAction(test))
    history.push("/contest-detail-upcoming");
  };

  const renderer = ({ days, hours, minutes, seconds, props, completed }) => {
    if (completed) {
      return <></>;
    } else {
      // Render a countdown
      if (days) {
        return <span className="days-left-span">{days} Days left </span>;
      } else {
        return (
          <span className="days-left-span">
            {hours.toString().padStart(2, "0")}:
            {minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
          </span>
        );
      }
    }
  };
  return (
    <>
      {noContest ? (
        <div className="d-flex m-5 height-fix">
          <Link to="page-contest">
            <span className="join-now">Join Now</span>
          </Link>
        </div>
      ) : null}

      {data.map(function (item, i) {
        let startDate = new Date(item.contest_relation?.contests_start_time);
        if (new Date() > startDate) {
          return null;
        }

        const sdate = startDate.getDate();
        const smonth = startDate.toLocaleString("default", { month: "short" }).padStart(2, "0");
        const shours = startDate.getHours().toString().padStart(2, "0");
        const sminutes = startDate.getMinutes().toString().padStart(2, "0");

        let endDate = new Date(item.contest_relation?.contests_end_time);
        const edate = endDate.getDate();
        const emonth = endDate.toLocaleString("default", { month: "short" }).padStart(2, "0");
        const ehours = endDate.getHours().toString().padStart(2, "0");
        const eminutes = endDate.getMinutes().toString().padStart(2, "0");

        let progressPercentage =
          (item.contest_relation?.join_spot /
            item.contest_relation?.max_contest_play) *
          50;

        return (
          <Fragment key={item.id}>
            <div className="portfolio-div">
              {item.total_portfolio} Portfolios
            </div>
            <div

              className="contest-card"
            >
              <div className="card-header-1">
                <span>
                  <img
                    src={iconic_trophy_icon}
                    alt="trophy"
                    className="small-icon"
                  />
                  {item.contest_relation?.win_ratio}
                </span>
                <span>
                  <img
                    src={guarantee_icon}
                    alt="trophy"
                    className="small-icon"
                  />
                  {item.contest_relation?.contest_category_name}
                </span>
              </div>
              <div className="d-flex mt--2">
                <div className="border-bottom-gray"></div>
                <div className="card-header-2">
                  <span>
                    <img src={trophy_icon} alt="trophy" className="mr-2" />
                    {item.contest_relation?.total_winning_coins}
                  </span>
                </div>
              </div>
              <div className="card-content d-flex justify-content-between">

                <div className="d-flex justify-content-between" onClick={() => cardClick(item.user_team_id)}>
                  {item.contest_relation.contest_image !== null ? (
                    <div>
                      <img
                        src={item.contest_relation?.contest_image}
                        alt="trophy"
                        className="upcoming-image"
                      />
                    </div>
                  ) : (
                    <span className="col-md-6 contest-title">
                      {item.contest_relation?.contest_title}
                    </span>
                  )}

                </div>
                <div onClick={() => cardClick(item.user_team_id)}>
                  <Countdown
                    className="col-md-6 counter-contest"
                    date={startDate}
                    renderer={renderer}
                    onComplete={(timeDelta, completedOnStart) => {
                      setData(Array.from(data));
                    }}
                  />
                </div>
                {item.contest_relation.user_join_count !== item.contest_relation.user_max_teams ?
                  <div>
                  <button
                    onClick={() => addMore(item)}
                    className="add-btn add-more-upcoming"
                  >
                    Add More +
                  </button>
                  </div>
              :
              <div>
                <button
                onClick={() => viewDetail(item.user_team_id)}
                className="add-btn add-more-upcoming"
                >
                  View Details
                </button>
              </div>
                }
            </div>
            <div className="contest-card-footer mt-1">
              <ProgressBar now={progressPercentage} variant="#380042" />
              <div className="card-content-footer d-flex justify-content-between">
                <div>
                  <span>
                    starts : {sdate} {smonth} | {shours}:{sminutes}
                  </span>
                </div>
                <div>
                  <span>
                    spots left :{" "}
                    {item.contest_relation?.max_contest_play -
                      item.contest_relation?.join_spot}
                  </span>
                </div>
                <div>
                  <span>
                    ends : {edate} {emonth} | {ehours}:{eminutes}
                  </span>
                </div>
              </div>
            </div>
          </div>
          </Fragment >
        );
})}
<div>
<Dropdown className="exchange-dropdown">
          <Dropdown.Toggle
            className="exchange-drop-btn"
            id="dropdown-basic"
          >
           {
            (exchangeID===1)?"IND": (exchangeID===2)?"US": (exchangeID===3)?"Crypto":null
           }
          </Dropdown.Toggle>

          <Dropdown.Menu className="exchange-drop-menu">
            <Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(1))}
              className="exchange-drop-item"
            >
              IND Stocks
            </Dropdown.Item>
            {/*<Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(3))}
              className="exchange-drop-item"
            >
              Crypto
            </Dropdown.Item>*/}
            <Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(2))}
              className="exchange-drop-item"
            >
              US Stocks
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
</div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(GlobalLeaderBoard);
