import { React, useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import iconic_trophy_icon from './../../images/icons/Icon ionic-ios-trophy (1).svg';
import trophy_icon from './../../images/icons/Icon metro-trophy (1).svg';
import guarantee_icon from './../../images/icons/Group 92799.svg';
import Countdown from 'react-countdown';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import spree_coin from './../../images/icons/spreecoin.svg';
import useRazorpay from "react-razorpay";
import back_icon from './../../images/icons/back arrow.svg';
import help_icon from './../../images/icons/help icon.svg';
import animation_icon from './../../images/icons/animation_500_lgdbznrx.gif';
import notification_icon from './../../images/icons/notifivation icon.svg';
import { useHistory, Link } from "react-router-dom";
import stepper_img from './../../images/icons/Group 36609.png'
import { Tooltip, OverlayTrigger, ProgressBar } from "react-bootstrap";
import i_icon from './../../images/icons/i button.svg';
import { calculateTimeLeft } from "../../lib/counter";
import { getUserWalletDataAction } from "../../store/actions/AuthActions";
import Spinner from "react-bootstrap/Spinner";

// import Slider from '@mui/material/Slider';
//

function JoinContest(props) {
  const [loading, setLoading] = useState(false);
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const [walletData, setWalletData] = useState()
  const [counterData, setCounterData] = useState()
  const [showCard, setShowCard] = useState(true);
  let history = useHistory();
  const selectedContest = useSelector(state => state.contest.selected_contest);
  const user = useSelector(state => state.auth?.auth?.user ?? {});
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
  const userTeamId = useSelector((state) => state.contest.user_team_id);
  const userWalletInfo = useSelector((state) => state.auth?.auth?.wallet);
  const [counter, setCounter] = useState(1);
  const contestName = useSelector(state => state.contest.selected_contest.contest_title);
  const startTime = useSelector(state => state.contest.selected_contest.contests_start_time);

  let startDate = new Date(startTime)

  const Completionist = () => {
    setShowCard(false)
  }
  let startDatej = new Date(selectedContest.contests_start_time)
  const sdate = startDate.getDate();
  const smonth = startDate.toLocaleString('default', { month: 'short' });
  const shours = startDate.getHours().toString().padStart(2, "0");
  const sminutes = startDate.getMinutes().toString().padStart(2, "0")

  let endDate = new Date(selectedContest.contests_end_time)
  const edate = endDate.getDate();
  const emonth = endDate.toLocaleString('default', { month: 'short' });
  const ehours = endDate.getHours().toString().padStart(2, "0");
  const eminutes = endDate.getMinutes().toString().padStart(2, "0")
  let progressPercentage = (selectedContest.join_spot / selectedContest.max_contest_play) * 50

  const fetchData = () => {
    const config = {
      method: 'get',
      url: `https://api.bullspree.com/api/v1/join-contest-initialization-v1?team_id=${userTeamId}`,
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log('hi', { response });
        setWalletData(response.data.original_data)
        console.log(walletData?.pay_coins, 'test it')
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const handlePayment = () => {
    if (walletData?.pay_coins === 0) {
      var data = {
        "team_id": userTeamId,
        "team_count": counter,
        "order_initialization_id": ''
      };

      let config = {
        method: 'post',
        url: `https://api.bullspree.com/api/v1/join-contest-step2`,
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        data: data
      };

      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          if (response.data.success) {
            await dispatch(getUserWalletDataAction());
            props.history.push('/my-games');
          }
          else {
            console.log(walletData?.pay_coins, 'test it inside ')
            let config = {
              method: 'get',
              url: `https://api.bullspree.com/api/v1/generate-order-id?team_id=${userTeamId}&amount=${counterData?.add_via_payment_gateway_in_coins}&coupon_id`,
              headers: {
                'Authorization': `Bearer ${authToken}`
              }
            };

            axios(config)
              .then(function (response) {
                let razorOrderData = response.data
                if (response.data.success) {
                  const RazorpayOptions = {
                    key: "rzp_live_kmKrcohcMv99Mj",
                    amount: (razorOrderData.original_data.amount/1000),
                    currency: "INR",
                    name: "Bullspree",
                    description: "Test Transaction",
                    order_id: razorOrderData.original_data.rayzorOrder,
                    handler: (res) => {
                      console.log({ res, user }, 'test for razor');
                      const { razorpay_signature, razorpay_payment_id, razorpay_order_id } = res;
                      var data = {
                        payment_status: "SUCCESS",
                        payment_id: razorpay_payment_id,
                        razorpay_signature,
                        team_id: userTeamId,
                        order_id: res.razorpay_order_id,
                        payment_response: {
                          razorpay_payment_id,
                          razorpay_signature,
                          razorpay_order_id
                        }
                      };

                      var config = {
                        method: 'post',
                        url: 'https://api.bullspree.com/api/v1/add-money-in wallet',
                        headers: {
                          'Authorization': `Bearer ${authToken}`
                        },
                        data: data
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(response.data, 'test resp of money');
                          if (response.data.success) {
                            var data = {
                              "team_id": userTeamId,
                              "team_count": counter,
                              "order_initialization_id": ''
                            };


                            let config = {
                              method: 'post',
                              url: `https://api.bullspree.com/api/v1/join-contest-step2`,
                              headers: {
                                'Authorization': `Bearer ${authToken}`
                              },
                              data: data
                            };

                            axios(config)
                              .then(async function (response) {
                                console.log(JSON.stringify(response.data));
                                if (response.data.success) {
                                  await dispatch(getUserWalletDataAction());
                                  props.history.push('/my-games');
                                }
                              })
                              .catch(function (error) {
                                console.log(error);
                              });

                          }
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    },
                    prefill: {
                      name: user.full_name,
                      email: user.email,
                      contact: user.phone,
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };

                  const rzpay = new Razorpay(RazorpayOptions);
                  rzpay.open();
                }

              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
    else {
      console.log(walletData?.pay_coins, 'test it inside ')
      let config = {
        method: 'get',
        url: `https://api.bullspree.com/api/v1/generate-order-id?team_id=${userTeamId}&amount=${counterData?.add_via_payment_gateway_in_coins}&coupon_id`,
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      };

      axios(config)
        .then(function (response) {
          let razorOrderData = response.data
          if (response.data.success) {
            const RazorpayOptions = {
              key: "rzp_live_kmKrcohcMv99Mj",
              amount: (razorOrderData.original_data.amount/1000),
              currency: "INR",
              name: "Bullspree",
              description: "Test Transaction",
              order_id: razorOrderData.original_data.rayzorOrder,
              handler: (res) => {
                console.log({ res, user }, 'test for razor');
                const { razorpay_signature, razorpay_payment_id, razorpay_order_id } = res;
                var data = {
                  payment_status: "SUCCESS",
                  payment_id: razorpay_payment_id,
                  razorpay_signature,
                  team_id: userTeamId,
                  order_id: res.razorpay_order_id,
                  payment_response: {
                    razorpay_payment_id,
                    razorpay_signature,
                    razorpay_order_id
                  }
                };

                var config = {
                  method: 'post',
                  url: 'https://api.bullspree.com/api/v1/add-money-in wallet',
                  headers: {
                    'Authorization': `Bearer ${authToken}`
                  },
                  data: data
                };

                axios(config)
                  .then(function (response) {
                    console.log(response.data, 'test resp of money');
                    if (response.data.success) {
                      var data = {
                        "team_id": userTeamId,
                        "team_count": counter,
                        "order_initialization_id": ''
                      };


                      let config = {
                        method: 'post',
                        url: `https://api.bullspree.com/api/v1/join-contest-step2`,
                        headers: {
                          'Authorization': `Bearer ${authToken}`
                        },
                        data: data
                      };

                      axios(config)
                        .then(async function (response) {
                          console.log(JSON.stringify(response.data));
                          if (response.data.success) {
                            await dispatch(getUserWalletDataAction());
                            props.history.push('/my-games');
                          }
                        })
                        .catch(function (error) {
                          console.log(error);
                        });

                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              },
              prefill: {
                name: user.full_name,
                email: user.email,
                contact: user.phone,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            };

            const rzpay = new Razorpay(RazorpayOptions);
            rzpay.open();
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const fetchCounter = () => {
    var data = {
      "team_id": userTeamId,
      "team_count": counter
    };

    var config = {
      method: 'post',
      url: 'https://api.bullspree.com/api/v1/join-contest-step1',
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        console.log(JSON.stringify(response.data, 'counter response data'));
        setCounterData(response.data.original_data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchCounter();
  }, [counter]);

  const decrementCount = () => {
    if (counter > 1) {
      setCounter(counter - 1)
    }

  }
  const incrementCount = () => {
    const spots_left = selectedContest.max_contest_play - selectedContest.join_spot;
    console.log({ selectedContest, spots_left });
    if (
      counter < selectedContest.user_max_teams - selectedContest.user_join_count &&
      counter < spots_left
    ) {
      setCounter(counter + 1);
    }
  }

  const disableIncrementButton = () => {
    const spots_left = selectedContest.max_contest_play - selectedContest.join_spot;
    if (
      counter < selectedContest.user_max_teams - selectedContest.user_join_count &&
      counter < spots_left
    ) {
      return false;
    }

    return true;
  }
  const disableDecrementButton = () => {
    if (counter === 1) {
      return true;
    }
    return false;
  }

  let timeLeftToStart = calculateTimeLeft(
    selectedContest.contests_start_time
  );

  const renderer = ({ days, hours, minutes, seconds, props, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      if (days) {
        return <span className="days-left-span">{days} Days left </span>;
      } else {
        return (
          <span className="days-left-span">
            {hours.toString().padStart(2, "0")}:
            {minutes.toString().padStart(2, "0")}:
            {seconds.toString().padStart(2, "0")}
          </span>
        );
      }
    }
  };

  return (

    <div className="home-page-container">
      <div className="header-wrapper-home contest-header-wrapper">
        <div className="header-bull">
          <div>
            <img
              src={back_icon}
              alt="back_icon"
              onClick={() => history.goBack()}
              className="back-icon"
            />
          </div>
          <div className="d-flex">
            <div className="position-relative">
              <Link to="fps-page">
                <img
                  src={animation_icon}
                  alt="wallet_icon"
                  className="animation_icon "
                />
                <img
                  src={help_icon}
                  alt="wallet_icon"
                  className="faq_icon "
                />
              </Link>
            </div>
            <div>
              <Link to="notification-page">
                <img
                  src={notification_icon}
                  alt="wallet_icon"
                  className="explore_icon_center"
                />
              </Link>
            </div>
            <div>
              <Link to="wallet-page">
                <div className="total-coins">
                  <img
                    src={spree_coin}
                    alt="wallet_icon"
                    className="img-fluid sm-logo mt-1"
                  />
                  <span style={{ fontSize: "12px" }}>
                    {userWalletInfo.total_amount_coins.toFixed(2)}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="header-content header-content-padd">
          <h4>{contestName}</h4>
          <div className="d-flex-count">
            <span className="mr-1">Starts in :</span>
            <div>
              <Countdown
                className="counter-contest"
                date={startDatej}
                timeLeft={timeLeftToStart}
                renderer={renderer}
              />
            </div>
          </div>
        </div>
        <div className="pos-fix-fix">
          <img
            src={stepper_img}
            alt="wallet_icon"
            className="img-fluid sm-logo join-stepper-img"
          />
        </div>
      </div>
      {loading ? (
          <Spinner
            animation="border"
            style={{
              color: "#530F50",
              left: "50%",
              position: "absolute",
              textAlign: "center",
              top: "50%",
            }}
          />
        ) : 
        (
       <>
      <div className="home-card-wrapper join-card-wrapper">
        <>
          <div className="contest-card-wraps">
            <div className="contest-card join-card-shadow">
              <div className="card-header-1">
                <span>
                  <img
                    src={iconic_trophy_icon}
                    alt="trophy"
                    className="small-icon"
                  />
                  {selectedContest.win_ratio}
                </span>
                <span>
                  <img
                    src={guarantee_icon}
                    alt="trophy"
                    className="small-icon"
                  />
                  {selectedContest.contest_category_name}
                </span>
              </div>
              <div className="d-flex">
                <div className="border-bottom-gray"></div>
                <div className="card-header-2">
                  <span>
                    <img src={trophy_icon} alt="trophy" className="mr-2" />
                    {selectedContest.total_winning_coins}
                  </span>
                </div>
              </div>
              <div className="card-content d-flex justify-content-between">
                {selectedContest.contest_image ? (
                  <div>
                    <img src={selectedContest.contest_image} alt="trophy" />
                  </div>
                ) : (
                  <span className="contest-title">
                    {selectedContest.contest_title}
                  </span>
                )}
                <div>
                  <Countdown
                    className="counter-contest"
                    date={startDate}
                    timeLeft={timeLeftToStart}
                    renderer={renderer}
                  />
                </div>
                <div>
                  <button className="play-btn">
                    <img src={spree_coin} alt="trophy" />
                    {selectedContest.entry_fees_coins}
                  </button>
                </div>
              </div>
              <div className="contest-card-footer mt-1">
                <ProgressBar now={progressPercentage} variant="#380042" />
                <div className="card-content-footer d-flex justify-content-between">
                  <div>
                    <span>
                      starts : {sdate} {smonth} | {shours}:{sminutes}
                    </span>
                  </div>
                  <div>
                    <span>
                      spots left :{" "}
                      {selectedContest.max_contest_play -
                        selectedContest.join_spot}
                    </span>
                  </div>
                  <div>
                    <span>
                      ends : {edate} {emonth} | {ehours}:{eminutes}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <div className="contest-entry-wallet-details">
          <div className="d-flex justify-content-between counter-box">
            <div className="d-flex">
              <span className="number-of-teams mr-4">No. of Teams :</span>
              <div>
                <button className="decre-btn" onClick={decrementCount} disabled={disableDecrementButton()}>
                  -
                </button>
                <span className="count-value">{counter}</span>
                <button
                  className="incre-btn"
                  onClick={incrementCount}
                  disabled={disableIncrementButton()}
                >
                  +
                </button>
              </div>
            </div>
            <div>
              {/* <img
                                src={help_icon}
                                alt="trophy"
                                className="mr-3"
                            /> */}
              <OverlayTrigger
                key={"bottom"}
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-${"bottom"}`}>
                    Joining the contest with multiple teams increases Your
                    winning chances by 40.
                  </Tooltip>
                }
              >
                {/* <Button className="infoo-icon-btn"> */}
                <img src={i_icon} alt="i_icon" className="" />
                {/* </Button> */}
              </OverlayTrigger>
            </div>
          </div>
          <div className="d-flex justify-content-between detail-box">
            <h4 className="text-center">Details</h4>
            <img src={spree_coin} alt="trophy" />
          </div>
          <div className="payment-box-detail">
            <div className="flex-box">
              <div>
                <span>Entry Fee</span>
              </div>
              <div>
                <span className="color-dark">
                  {counterData?.total_entry_fees_coins}
                </span>
              </div>
            </div>
            <div className="flex-box">
              <div>
                <span>Wallet</span>
              </div>
              <div>
                <span className="color-dark">
                  {counterData?.total_used_wallet_amount_in_coins}
                </span>
              </div>
            </div>
            <div className="flex-box">
              <div>
                <span>Bonus</span>
              </div>
              <div>
                <span className="color-dark">
                  {counterData?.used_bonus_amount_in_coins}
                </span>
              </div>
            </div>
            <div className="flex-box">
              <div>
                <span>Buy via payment gateway</span>
              </div>
              <div>
                <span className="color-dark">
                  {counterData?.add_via_payment_gateway_in_coins}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button onClick={handlePayment} className="select-stock-btn">
        Continue
      </button>
      </>   
        )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(JoinContest);
