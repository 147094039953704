import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import i_icon from './../../images/icons/i icon.svg';
import spree_coin from './../../images/icons/spreecoin.svg';
import download_app from './../../images/icons/Group 127650.png';
import lock_icon from './../../images/icons/Mask Group 374.png';
import unlock_icon from './../../images/icons/Image 297.png';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import back_icon from './../../images/icons/back.svg';
import down_icon from './../../images/icons/know more down arrow.svg';


//
function Wallet(props) {
	const [modalCentered, setModalCentered] = useState(false);
	const [modalCenteredKK, setModalCenteredKK] = useState(false);
	const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
	const [walletData, setWalletData] = useState()
	const [showDrop, setShowDrop] = useState(false)
	const [hideDrop, setHideDrop] = useState(true)
	const [modalCenteredPP, setModalCenteredPP] = useState(false);
	const [modalCenteredGG, setModalCenteredGG] = useState(false);
	const [modalCenteredWW, setModalCenteredWW] = useState(false);
	var axios = require('axios');

	let history = useHistory();

	useEffect(() => {

		var config = {
			method: 'get',
			url: 'https://api.bullspree.com/api/v1/get-user-wallets?page=1&bonus=false',
			headers: {
				'Authorization': `Bearer ${authToken}`,
			}
		};

		axios(config)
			.then(function (response) {
				console.log(response.data.original_data.wallet)
				setWalletData(response.data.original_data.wallet)
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const walletDetails = () => {
		var config = {
			method: 'get',
			url: 'https://api.bullspree.com/api/v1/withdraw-amount-details',
			headers: {
				'Authorization': `Bearer ${authToken}`,
			}
		};

		axios(config)
			.then(function (response) {
				console.log(response.data, 'test wallet details');
				if (response.data.success) {
					props.history.push('/tds')
				}

			})
			.catch(function (error) {
				console.log(error.response.status, 'test status widd');
				if (error.response.status === 512) {
					setModalCenteredKK(true)
				}
			});
	}

	const handleWallet = () => {
		console.log("i was clicked wallet")

	}
	const handleWrapClick = () => {
		if (walletData?.total_user_withdrawable_coins >= 1000) {
			walletDetails()
			//props.history.push('/withdraw-page')
		}

		else {
			setModalCenteredPP(true)
		}

	}
	const iconClick = () => {
		if (walletData?.total_user_withdrawable_coins < 1000) {
			setModalCenteredWW(true)
			//props.history.push('/withdraw-page')
		}
	}
	return (
		<>
			<Modal className="fade modal-full-view" show={modalCenteredGG}>
				<Modal.Body>
					<div className='img-wrapper-mod-gg learn refer'>
						<div className='btn-wrapper-mod'>
							<button onClick={() => setModalCenteredGG(false)} className='round-btn-close'>x</button>
						</div>
						<a href='https://cdn.bullspree.live/downloads/Bullspree.apk' className='download-app-gg'>

						</a>
					</div>
				</Modal.Body>

			</Modal>
			<Modal className="fade bottom-modal" show={modalCenteredKK}>
				<Modal.Body>
					<p>Please verify your account for redemption</p>
				</Modal.Body>
				<Modal.Footer className='justify-content-center modal-foot-pad'>
					<Link to='account-verification'>
						<button className='tds-modal-now-btn'>
							Verify
						</button>
					</Link>
					<Button
						onClick={() => setModalCenteredKK(false)}
						className="tds-modal-later-btn wallet-btn"
					>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal className="fade bottom-modal" show={modalCenteredPP}>
				<Modal.Body>
					<h5>Winnings</h5>
					<p>You need minimum {(1000 - walletData?.total_user_withdrawable_coins).toFixed(2)} winnings to redeem</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => setModalCenteredPP(false)}
						className="tds-modal-later-btn wallet-btn"
					>
						Understood
					</Button>
				</Modal.Footer>
			</Modal>

			<div className='top-head-logo mb-25'>
				<div>
					<img
						src={back_icon}
						alt="back_icon"
						onClick={() => history.goBack()}
						className="back-icon"
					/>
				</div>
				<div>
					<h4 className='m-0 noti-head'>My Spree Coins</h4>
				</div>
			</div>
			<div className='wallet-detail-wrapper'>
				<div className='wallet-header-card'>
					<div>
						<h4 className='wallet-price-type'>Add Spree Coins</h4>
					</div>
					<div >
						<Link to='add-money'>
							<button className='wallet-btn-new'>Add now</button>
						</Link>
					</div>
				</div>
				{/* <div className='wallet-header-card'> */}
				<div className='wallet-header-card-2'>

					<span className='wallet-price-type d-block'>Withdrawable Winnings</span>
					<img
						src={spree_coin}
						alt="wallet_icon"
						className="img-fluid sm-logo"
					/>
					<span className='wallet-price'>{walletData?.total_user_withdrawable_coins}</span>
					<Modal className="fade bottom-modal" show={modalCenteredWW}>
						<Modal.Body>
							<h5>Winnings</h5>
							<p>You need minimum {(1000 - walletData?.total_user_withdrawable_coins).toFixed(2)} winnings to redeem</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={() => setModalCenteredWW(false)}
								className="tds-modal-later-btn wallet-btn"
							>
								Understood
							</Button>
						</Modal.Footer>
					</Modal>
					<img
						
						onClick={iconClick}
						src={i_icon}
						alt="i_icon"
						className="img-fluid sm-logo mb-2"
					/>
					<div className="progress-wrapper wallet-progress">
						<ProgressBar max="1000" className='reddem-progres-bar' now={walletData?.total_user_withdrawable_coins} />

						<button onClick={handleWrapClick} className='withdraw-btn'>Withdraw</button>
					</div>
					<Modal className="fade bottom-modal" show={modalCentered}>
						<Modal.Body>
							<p>We encourage you to not withdraw frequently and have a 30% extra amount to play contests</p>
						</Modal.Body>
						<Modal.Footer className='justify-content-center modal-foot-pad'>
							<Link to='withdraw-page'>
								<button onClick={() => handleWallet()} className='tds-modal-now-btn'>
									Withdraw Now
								</button>
							</Link>
							<Button
								onClick={() => setModalCentered(false)}
								className="tds-modal-later-btn wallet-btn"
							>
								Withdraw Later
							</Button>
						</Modal.Footer>
					</Modal>
					<div className='throphy-wraper-2'>
						{
							Number(walletData?.total_user_withdrawable_coins) > 1000 ?
								<img
									className='wallet-trophy-2'
									src={unlock_icon}
									alt="unlock_icon"
								/> : <img
									className='wallet-trophy-2'
									src={lock_icon}
									alt="lock_icon"
								/>
						}

					</div>
				</div>

				{/* </div> */}
			</div>
			<div className='wallet-know-wrapper'>
				{hideDrop ?
					<div className='wallet-card-main d-flex justify-content-between'>
						<div>
							<span className='d-block bonus-span total-span'>Total spree coins</span>
							<img
								src={spree_coin}
								alt="wallet_icon"
								className="img-fluid sm-logo"
							/>
							<span className='wallet-price text-bull'>{walletData?.total_amount_coins.toFixed(2)}</span>
						</div>
						<div >
							<button onClick={() => { return (setShowDrop(true), setHideDrop(false)) }} className='wallet-btn-drop'>Know More <img
								src={down_icon}
								alt="down_icon"
								className="ml-2"
							/></button>
						</div>
					</div>
					: null}
				{showDrop ?
					<div className='drop-visible-div-wrapper'>
						<div className='wallet-card d-flex bt-10 justify-content-between'>
							<div>
								<span className='d-block bonus-span total-span'>Winnings</span>
								<img
									src={spree_coin}
									alt="wallet_icon"
									className="img-fluid sm-logo"
								/>
								<span className='wallet-price text-bull'>{walletData?.total_winning_coins}</span>
							</div>

						</div>
						<div className='wallet-card d-flex justify-content-between'>
							<div>
								<span className='d-block bonus-span total-span'>Deposit</span>
								<img
									src={spree_coin}
									alt="wallet_icon"
									className="img-fluid sm-logo"
								/>
								<span className='wallet-price text-bull'>{walletData?.total_deposit_coins}</span>
							</div>
							<div >
								<Link to='add-money'>
									<button className='wallet-btn'>Add More</button>
								</Link>
							</div>
						</div>
						<div className='wallet-card d-flex justify-content-between'>
							<div>
								<span className='d-block bonus-span'>Bonus</span>
								<img
									src={spree_coin}
									alt="wallet_icon"
									className="img-fluid sm-logo"
								/>
								<span className='wallet-price text-bull'>{walletData?.total_bonus_coins}</span>
							</div>
							<div >
								<button onClick={() => setModalCenteredGG(true)} className='wallet-btn total-span'>Refer & earn</button>
							</div>
						</div>
						<div className='wallet-card-main mt-0 d-flex justify-content-between'>
							<div>
								<span className='d-block bonus-span total-span'>Total Spreecoins</span>
								<img
									src={spree_coin}
									alt="wallet_icon"
									className="img-fluid sm-logo"
								/>
								<span className='wallet-price text-bull'>{walletData?.total_amount_coins}</span>
							</div>
							<div >
								<button onClick={() => { return (setShowDrop(false), setHideDrop(true)) }} className='wallet-btn-drop'>{`^`}</button>
							</div>
						</div>
					</div>
					: null}
				<div>
					<a href='https://cdn.bullspree.live/downloads/Bullspree.apk'>
						<img

							src={download_app}
							alt="wallet_icon"
							className="img-fluid sm-logo"
						/>
					</a>
				</div>
				{/* <div className='wallet-card-main  p-3 mt-0 d-flex justify-content-between'>
					<div>
						<h4 className='m-0'>Transaction History</h4>
					</div>
					<div >
						<Link to='transaction'>
							<button className='wallet-btn'>View All</button>
						</Link>
					</div>
				</div> */}
			</div>


		</>

	)
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Wallet);