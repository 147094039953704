import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import back_icon from './../../images/icons/back.svg';
import { Card, Accordion } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

function FPS(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    
    const [activeDefault, setActiveDefault] = useState(0)
    const [accordianDta, setAccordianData] = useState([])
    let history = useHistory();
    var axios = require('axios');

    
    useEffect(() => {

        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/how-to-play?page=1&user_level=1',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }

        };

        axios(config)
            .then(function (response) {
                setAccordianData(response.data.original_data.how_to_play.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])

    return (
        <>
            <div className='wrapper'>
                <div className='top-head-logo mb-25'>
                    <div>
                        <img
                            src={back_icon}
                            alt="back_icon"
                            onClick={() => history.goBack()}
                            className="back-icon"
                        />
                    </div>
                </div>
                <div className='tab-bull-page'>
                    <Tabs
                        defaultActiveKey="HTP"
                        id="uncontrolled-tab-example"
                        className="bull-tabs"
                    >
                        <Tab className='tab-1' eventKey="HTP" title="HOW TO PLAY">
                            <div>
                                <div className='video-wrapper-htp'>
                                    <video className='video-htp' controls>
                                        <source src="https://d1skprem8u1vry.cloudfront.net/videos/beginner_video.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div>


                                    {/* <!-- Default accordion --> */}
                                    <Accordion
                                        className='accordion accordion-primary'
                                        defaultActiveKey='0'
                                    >
                                        {Array.isArray(accordianDta) && accordianDta.map(function (d, i) {
                                            return (

                                                <div className='accordion__item' key={i}>
                                                    <Accordion.Toggle
                                                        as={Card.Text}
                                                        eventKey={`${i}`}
                                                        className={`accordion__header rounded-lg ${activeDefault === i ? '' : 'collapsed'
                                                            }`}
                                                        onClick={() =>
                                                            setActiveDefault(activeDefault === i ? -1 : i)
                                                        }
                                                    >
                                                        <span className='accordion__header--text'>{d?.title}</span>
                                                        <span className='accordion__header--indicator'>

                                                        </span>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={`${i}`}>

                                                        <div className='accordion__body--text'>
                                                            <div className='video-wrapper-htp'>
                                                                <video className='video-htp' controls>
                                                                    <source src={d?.video_url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                            {parse(d?.long_body)}</div>
                                                    </Accordion.Collapse>
                                                </div>

                                            )
                                        })
                                        }
                                    </Accordion>
                                </div>
                            </div>
                        </Tab>
                        <Tab className='tab-2' eventKey="FPS" title="FPS">
                            <div className='fps-wrapper'>
                                <div>
                                    <h4>Contest Coin Calculation</h4>
                                </div>
                                <div className='fps-content'>
                                    <p>Final value of coins earned for a particular stock = {"((Virtual Coins Invested))*(End Price))/(Starting Price)"}</p>
                                    <p>Virtual Coins Invested = The coins you have invested in a particular stock of a particular contest</p>
                                    <p>End Price = stock price at the end of the contest time</p>
                                    <p>Starting Price = stock price at the start of the contest time</p>
                                    <p>For instance, You invested 1000 coins in ABC company and contest will start at 12:30 PM and endat 1:00 PM.</p>
                                    <p>ABC company's stock price at 12:30 PM = 100</p>
                                    <p>ABC company's stock price at 1:00 PM = 120</p>
                                    <p>Final value of coins earned in ABC company = {"(1000*120)/100 = 1200"}</p>
                                </div>
                            </div>
                        </Tab>

                    </Tabs>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(FPS);