import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux';
import AppFooter from '../layouts/AppFooter';
import explore_icon from './../../images/icons/explore boxes.svg';
import help_icon from './../../images/icons/help icon.svg';
import animation_icon from './../../images/icons/animation_500_lgdbznrx.gif';
import notification_icon from './../../images/icons/notifivation icon.svg';
import spree_coin from './../../images/icons/spreecoin.svg';
import { Link, useParams } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyGamesTab from './MyGamesTab';
import { Card, Carousel, Modal } from 'react-bootstrap'

function MyGames(props) {
    let userWalletInfo = useSelector((state) => state.auth?.auth?.wallet);
    const { t1 = "league", t2 = 'UPCOMING' } = useParams();
    const [modalCenteredGG, setModalCenteredGG] = useState(false);
    const [modalCenteredPP, setModalCenteredPP] = useState(false);
    const [modalCenteredRR, setModalCenteredRR] = useState(false);
    return (
        <>
         <Modal className="fade modal-full-view" show={modalCenteredGG}>
                    <Modal.Body>
                        <div className="img-wrapper-mod-gg learn explore">
                            <div className="btn-wrapper-mod">
                                <button
                                    onClick={() => setModalCenteredGG(false)}
                                    className="round-btn-close-explore"
                                >
                                    x
                                </button>
                            </div>
                            <button
                                onClick={() => setModalCenteredPP(true)}
                                className="download-app-gg predict"
                            ></button>
                            <button
                                onClick={() => setModalCenteredRR(true)}
                                className="download-app-gg refer"
                            ></button>
                            <a
                                href="https://cdn.bullspree.live/downloads/Bullspree.apk"
                                className="download-app-gg"
                            ></a>
                        </div>
                    </Modal.Body>
                </Modal>
            <div className='home-page-container'>
                <div className='header-bull bg-white'>
                    
                    <div onClick={() =>
                        setModalCenteredGG(true)
                    } className='explore-wrapper'>
                        <img
                            src={explore_icon}
                            alt="wallet_icon"
                            className="explore_icon mr-2"
                        />
                        <span>Explore</span>
                    </div>
                    <div className='d-flex'>
                        <div className='position-relative'>
                            <Link to='fps-page'>
                                <img
                                    src={animation_icon}
                                    alt="wallet_icon"
                                    className="animation_icon "
                                />
                                <img
                                    src={help_icon}
                                    alt="wallet_icon"
                                    className="faq_icon "
                                />
                            </Link>
                        </div>
                        <div>
                            <Link to="notification-page">
                                <img
                                    src={notification_icon}
                                    alt="wallet_icon"
                                    className="explore_icon_center"
                                />
                            </Link>
                        </div>
                        <div>
                            <Link to='wallet-page'>
                                <div className='total-coins'>
                                    <img
                                        src={spree_coin}
                                        alt="wallet_icon"
                                        className="img-fluid sm-logo mt-1"
                                    />
                                    <span style={{ "fontSize": "12px" }}>{userWalletInfo.total_amount_coins.toFixed(2)}</span></div>
                            </Link>
                        </div>
                    </div>


                </div>
                <Tabs
                    defaultActiveKey={t1}
                    id="uncontrolled-tab-example"
                    className="mygames-tabs"
                >
                    <Tab eventKey="league" title="League">
                        <div className='home-page-content pt-110 dark-bg'>
                            <MyGamesTab defaultActiveTab={t2} />
                        </div>
                    </Tab>
                    <Tab eventKey="predict" title="Predict & Win">
                        <div className='img-wrapper-mod'>
                        </div>
                        <div className='download-btn-wrapper top-new'>
                            <a className='download-app-btn' href='https://cdn.bullspree.live/downloads/Bullspree.apk'>Download Now </a>
                        </div>
                    </Tab>
                </Tabs>

                <AppFooter />
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(MyGames);