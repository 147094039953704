import { React, useEffect, useState, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import stock_up_icon from "./../../images/icons/vuesax-linear-danger.svg";
import stock_down_icon from "./../../images/icons/vuesax-linear-danger-1.svg";
import stock_ppl from "./../../images/icons/Icon material-people (1).svg";
import filter_icon from "./../../images/icons/Icon metro-equalizer-v.svg";
import next_icon from "./../../images/icons/arrow next.svg";
import axios from "axios";
import HeaderStock from "../layouts/HeaderStock";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useHistory } from "react-router-dom";
import ReactSlider from "react-slider";
import Switch from "react-switch";
import { Modal, Button } from "react-bootstrap";
import "../../scss/pages/_contest-stocks.css";
import { selectUserTeamAction } from "../../store/actions/ContestAction";

function ContestStocks(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedContest = useSelector(
    (state) => state.contest.selected_contest
  );
  const editSelectedStocks = useSelector((state) => state.contest.selected_contest?.stockDetails??[])
  const user = useSelector((state) => state.auth.auth.user);
  const [modalCenteredGG, setModalCenteredGG] = useState(false);
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
  const [amountLeftValue, setAmountLeftValue] = useState(
    selectedContest.entry_fees
  );
  const [data, setData] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [dataAndSymbols, setDataAndSymbols] = useState([]);
  // To toggle step views
  const [showSelectStock, setShowSelectStock] = useState(true);
  const [showInvestStock, setShowInvestStock] = useState(false);
  const [modalCenteredPP, setModalCenteredPP] = useState(false);
  const [modalCenteredTT, setModalCenteredTT] = useState(false);
  const [modalCentered, setModalCentered] = useState(false);
  const setOfSector = useMemo(() => new Set(), []);
  const stocksMap = useMemo(() => new Map(), []);
  let stocksMapFilteredAndSearched = useMemo(() => new Map(), []);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("alphabetically");
  const [searchValue, setSearchValue] = useState("");
  const [renderScreen, setRenderScreen] = useState(0);
  const [autoSelect, setAutoSelect] = useState(true);

  const fetchData = () => {
    var config = {
      method: "get",
      url: `https://api.bullspree.com/api/v1/get-contests-company-list?contest_id=${selectedContest.id}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const symbolArr = [];
    var stocksArray = [];
    axios(config)
      .then((response) => {
        stocksArray.push(
          ...response.data.original_data.LARGE_CAP.cap_company_credits_relation
        );
        stocksArray.push(
          ...response.data.original_data.MID_CAP.cap_company_credits_relation
        );
        stocksArray.push(
          ...response.data.original_data.SMALL_CAP.cap_company_credits_relation
        );
        const selected = editSelectedStocks.map(s => s.stockSymbol);
          console.log(editSelectedStocks, stocksArray,'editSelectedStocks')
        stocksArray = stocksArray.map((stock, index) => {
          // Keeping track of all sectors available
          setOfSector.add(stock.company_relation.sectors);

          return {
            ...stock,
            selected: selectedContest.isEditable ? selected.includes(stock.company_relation?.symbol): false,
            coins: Math.floor((amountLeftValue * 100) / stocksArray.length),
          };
        });
        setData(stocksArray);
        stocksArray.forEach((stock) => {
          return symbolArr.push(stock.company_relation.symbol);
        });

        let data = JSON.stringify({
          symbols: symbolArr,
        });
        console.log(data, symbolArr, "symbol data");
        var config = {
          method: "post",
          url: "https://apinode-qa.bullspree.com/v1/contest/contestSymbolPrice",
          headers: {
            "Content-Type": "application/json",
          },
          data,
        };

        axios(config)
          .then((response) => {
            setSymbols(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Method for Search
  const handleSearchInputChange = (event) => {
    console.log(event.target.value);
    setSearchValue(event.target.value);
  };

  // All methods for filter modal
  const handleFilterOptionsClose = () => setShowFilterOptions(false);
  const handleFilterOptionsShow = () => setShowFilterOptions(true);
  const handleReset = () => {
    setShowFilterOptions(false);
    setSelectedFilter("alphabetically");
  };

  const handleOptionChange = (changeEvent) => {
    setSelectedFilter(changeEvent.target.value);
    setShowFilterOptions(false);
  };

  // This useEffect will be called only once to create Map data
  useEffect(() => {
    if (symbols.length > 0 && data.length > 0) {
      const result = [];
      data.forEach((stock) => {
        const symbol = symbols.find(
          (s) => s.symbol === stock.company_relation.symbol
        );
        if (symbol) result.push({ ...symbol, ...stock });
      });

      // createMap
      setOfSector.forEach((sector) => {
        let sameSectorStocks = [];
        result.forEach((stock) => {
          if (stock.sectors === sector) {
            sameSectorStocks.push(stock);
          }
        });
        stocksMap.set(sector, sameSectorStocks);
      });

      setDataAndSymbols(result); // right now not in use, but will be used in future - this is backup data
    }
  }, [symbols]);

  // Search & Filter effects
  useEffect(() => {
    if (stocksMap.size > 0) {
      for (let [key, value] of stocksMap) {
        let includeValues = value.filter((item) =>
          item.stockname.toLowerCase().includes(searchValue.toLowerCase())
        );
        stocksMapFilteredAndSearched.set(key, includeValues);
      }
    }

    if (stocksMapFilteredAndSearched.size > 0) {
      switch (selectedFilter) {
        case "alphabetically":
          for (let [key, value] of stocksMapFilteredAndSearched) {
            // Sorting based on stockname
            value.sort((a, b) => {
              const nameA = a.stockname.toUpperCase();
              const nameB = b.stockname.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            stocksMapFilteredAndSearched.set(key, value);
          }

          break;
        case "popularity":
          for (let [key, value] of stocksMapFilteredAndSearched) {
            // Sorting based on popularity or used_percentage
            value.sort((a, b) => {
              const usedPercentageA = a.used_percentage;
              const usedPercentageB = b.used_percentage;
              return usedPercentageB - usedPercentageA;
            });
            stocksMapFilteredAndSearched.set(key, value);
          }
          break;
        case "topLoser":
          for (let [key, value] of stocksMapFilteredAndSearched) {
            // Sorting based on Top Losers or percent_change
            value.sort((a, b) => {
              const percentageChangeA = Number(a.percent_change);
              const percentageChangeB = Number(b.percent_change);
              return percentageChangeA - percentageChangeB;
            });
            stocksMapFilteredAndSearched.set(key, value);
          }
          break;
        case "topGainer":
          for (let [key, value] of stocksMapFilteredAndSearched) {
            // Sorting based on Top Gainers or percent_change
            value.sort((a, b) => {
              const percentageChangeA = Number(a.percent_change);
              const percentageChangeB = Number(b.percent_change);
              return percentageChangeB - percentageChangeA;
            });
            stocksMapFilteredAndSearched.set(key, value);
          }
          break;
      }
      setRenderScreen((prev) => prev + 1); // To rerender application and list to show filtered data
      // console.log("stocksMapFilteredAndSearched= ", stocksMapFilteredAndSearched);
    }
  }, [selectedFilter, searchValue, dataAndSymbols]);

  const toggleCardElement = (item) => {
    const index = data.findIndex((e) => e.id === item.id);

    if (index >= 0) {
      if (item.selected) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    }
    const newData = Array.from(data);
    newData[index] = Object.assign({}, item);
    setData(newData);
  };

  const selectStock = () => {
    console.log("Called selectStock");
    const selectedStocks = data.filter((i) => i.selected);
    if (selectedStocks.length >= 2 && selectedStocks.length <= 20) {
      const newData = distributeCoins(data);

      console.log({ selectedStocks, amountLeftValue, newData });
      setData(newData);
      setShowSelectStock(false);
      setShowInvestStock(true);
    } else if (selectedStocks.length < 2) {
      setModalCentered(true);
    } else if (selectedStocks.length >= 20) {
      setModalCenteredPP(true);
    }
  };

  const distributeCoins = (data)=>{
    const selectedStocks = data.filter((i) => i.selected);
    let remainingAmount = amountLeftValue * 100;
    const coins = Math.floor(remainingAmount / selectedStocks.length);
    const newData = Array.from(data).map((stock, index) => {
      if (stock.selected) {
        if (coins * 2 <= remainingAmount) {
          stock.coins = coins;
          remainingAmount -= coins;
        } else {
          stock.coins = remainingAmount;
        }
        console.log('Stocks ke coins set hue', stock.coins);
      }
      return stock;
    });

    return newData;
  }

  const handleAutoSelect = (checked) => {
    if (checked) {
      const newData = distributeCoins(data);
      console.log({ newData });
      setData(newData);
    }
    setAutoSelect(checked);
  };

  const handleContinueClick = () => {
    
    const selectedAmount = getSelectedAmount();
    if (amountLeftValue - selectedAmount > 0) {
      return false;
    }

    const selectedStocks = data.filter((i) => i.selected);
    if (selectedStocks.length > 20) {
      setModalCenteredPP(true);
    }
    var valRandom = Math.floor(1000 + Math.random() * 9000);
    let userName = user?.full_name
    
    let new_portfolio_name =  valRandom + user?.full_name
    
    if(selectedContest?.isEditable){

      let body = JSON.stringify({
        team_id:selectedContest.user_teamId,
        contest_id: selectedContest.id,
        match_id: selectedContest.match_id,
        total_utilize_credit: selectedContest.total_credits,
        unique_user_team: user.user_portfolio_name ? user.user_portfolio_name : new_portfolio_name, // if user.user_portfolio === null or undefined, provide new portfolio name with first 3 digit of name and some 4 digit random number
        //in case of editable selected stocks should be selected
        selected_company: selectedStocks.map((item) => ({
          id: item.id,
          company_id: item.company_id,
          bull_credits: item.bull_credits,
          cap_id: item.cap_id,
          play_type: "bear",
          coins: item.coins,
        })),
      });
      var config = {
        method: "post",
        url: "https://api.bullspree.com/api/v1/create-user-team-for-beginner",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data: body,
      };
  
      axios(config)
        .then(function (response) {
          
          const { success, original_data, message } = response.data;
          if (success) {
            let userTeamId = original_data?.user_team?.id;
            dispatch(selectUserTeamAction(userTeamId));
            if(selectedContest?.isEditable){
              history.push('/my-games');
            }else{
              history.push("join-contest");
            }
            setModalCenteredTT(true)
          } else {
            alert(message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
        setModalCenteredTT(true)

        
  
    }else{
      let body = JSON.stringify({
        contest_id: selectedContest.id,
        match_id: selectedContest.match_id,
        total_utilize_credit: selectedContest.total_credits,
        unique_user_team: user.user_portfolio_name ? user.user_portfolio_name : new_portfolio_name, // if user.user_portfolio === null or undefined, provide new portfolio name with first 3 digit of name and some 4 digit random number
        selected_company: selectedStocks.map((item) => ({
          id: item.id,
          company_id: item.company_id,
          bull_credits: item.bull_credits,
          cap_id: item.cap_id,
          play_type: "bear",
          coins: item.coins,
        })),
      });
  
      var config = {
        method: "post",
        url: "https://api.bullspree.com/api/v1/create-user-team-for-beginner",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        data: body,
      };
  
      axios(config)
        .then(function (response) {
          
          const { success, original_data, message } = response.data;
          if (success) {
            let userTeamId = original_data?.user_team?.id;
            dispatch(selectUserTeamAction(userTeamId));
            if(selectedContest?.isEditable){
              history.push('/my-games');
            }else{
              history.push("join-contest");
            }
          } else {
            alert(message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  
        
  
    }
    
    
  };

  const getSelectedAmount = () => {
    let selectedAmount = 0;
    for (const item of data) {
      if (item.selected) {
        selectedAmount += item.coins;
      }
    }
    return selectedAmount;
  };

  const getRemainingAmount = () => {
    return ((amountLeftValue * 100) - getSelectedAmount()).toFixed(2);
  };

  const noOfStocksSelected = data.filter((i) => i.selected).length;

  return (
    <>
      <Modal className="fade modal-full-view" show={modalCenteredGG}>
        <Modal.Body>
          <div className="img-wrapper-mod-gg learn script">
            <div className="btn-wrapper-mod">
              <button
                onClick={() => setModalCenteredGG(false)}
                className="round-btn-close"
              >
                x
              </button>
            </div>
            <a
              href="https://cdn.bullspree.live/downloads/Bullspree.apk"
              className="download-app-gg"
            ></a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={modalCenteredPP}>
        <Modal.Body>
          <p>You can only select maximum of 20 stock</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCenteredPP(false)}
            className="tds-modal-later-btn wallet-btn"
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade" show={modalCenteredTT}>
        <Modal.Body>
          <h5>Team Edited Succesfully</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCenteredTT(false)}
            className="tds-modal-later-btn wallet-btn"
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" show={modalCentered}>
        <Modal.Body>
          <h5>Oops !</h5>
          <p>select minimum of 2 stock</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalCentered(false)}
            className="tds-modal-later-btn wallet-btn"
          >
            Understood
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade bottom-modal"
        show={showFilterOptions}
        onHide={handleFilterOptionsClose}
      >
        <div className="d-flex">
          <Button className="filter-reset-btn" onClick={handleReset}>
            Reset
          </Button>
        </div>
        <Modal.Body>
          <form>
            <div className="radio d-flex justify-content-between">
              <label>Top gainer</label>
              <input
                type="radio"
                value="topGainer"
                checked={selectedFilter === "topGainer"}
                onChange={handleOptionChange}
              />
            </div>
            <div className="radio d-flex justify-content-between">
              <label>Top Loser</label>
              <input
                type="radio"
                value="topLoser"
                checked={selectedFilter === "topLoser"}
                onChange={handleOptionChange}
              />
            </div>
            <div className="radio d-flex justify-content-between">
              <label>Popularity</label>
              <input
                type="radio"
                value="popularity"
                checked={selectedFilter === "popularity"}
                onChange={handleOptionChange}
              />
            </div>
            <div className="radio d-flex justify-content-between">
              <label>Alphabetically</label>
              <input
                type="radio"
                value="alphabetically"
                checked={selectedFilter === "alphabetically"}
                onChange={handleOptionChange}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="home-page-container">
        <HeaderStock />
        {showSelectStock ? (
          <>
            <div className="filter-search-wrapper">
              <div className="search-box">
                <button className="btn-search">
                  <i className="fas fa-search"></i>
                </button>
                <input
                  type="search"
                  id="Search"
                  className="input-search"
                  name="Search"
                  onChange={handleSearchInputChange}
                  value={searchValue}
                  placeholder="Search..."
                />
              </div>

              <h4 className="bull-text text-center">Select Minimum 2 Stocks</h4>
              <img
                onClick={handleFilterOptionsShow}
                src={filter_icon}
                className="filter_icon"
                alt="filter_icon"
              />
              {/* <Button onClick={handleFilterOptionsShow}>
                Filter</Button> */}
            </div>

            <div className="stock-wrapper-main">
              {stocksMapFilteredAndSearched.size > 0
                ? Array.from(stocksMapFilteredAndSearched.keys()).map(
                    (sector) => {
                      if (
                        stocksMapFilteredAndSearched.get(sector).length == 0
                      ) {
                        return null; // if the sector does not have any stock in it
                      } else {
                        return (
                          <div className="sector-container" key={sector}>
                            <div className="sector-heading-wrapper">
                              <h5>{sector}</h5>
                            </div>
                            <div className="stock-list-per-sectore">
                              {stocksMapFilteredAndSearched
                                .get(sector)
                                .map((stock) => (
                                  <div
                                    key={stock.id}
                                    className={`stock-info-div${
                                      stock.selected ? " selected" : ""
                                    }`}
                                  >
                                    <div className="d-flex">
                                      <img
                                        src={
                                          stock.company_relation.profile_image
                                        }
                                        className="stock_icon_img"
                                       // alt={stock.stockname}
                                      />
                                      <div className="stock-name-wrapper">
                                        <h3
                                          onClick={() =>
                                            setModalCenteredGG(true)
                                          }
                                        >
                                          {stock.stockname}
                                        </h3>
                                        <span>
                                          {stock.percent_change != 0 ? (
                                            <img
                                              src={
                                                Number(stock.percent_change) > 0
                                                  ? stock_up_icon
                                                  : stock_down_icon
                                              }
                                              alt="stock_icon"
                                              className="stock_change_img"
                                            />
                                          ) : null}
                                          <span className="stock-percentage-change">
                                            {stock?.percent_change}%
                                          </span>
                                          <span className="stock-current-price">
                                            {stock?.current_price}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="stock-selection-count">
                                        <img
                                          src={stock_ppl}
                                          alt="stock_icon"
                                          className="stock_change_img"
                                        />
                                        <span>{stock.used_percentage} %</span>
                                      </div>
                                      <div className="add-stock-btn">
                                        <button
                                          onClick={() =>
                                            toggleCardElement(stock)
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        );
                      }
                    }
                  )
                : // <h5>No stocks available in this contest right now</h5>}
                  null}
            </div>
            <div className="login-button-wrapper">
              <button
                type="submit"
                className="login-btn-dark inner-btn-pos"
                onClick={selectStock}
                disabled={noOfStocksSelected < 2}
                style={{
                  background: (noOfStocksSelected < 2) ? "#9E9E9E" : "#42003F",
                }}
              >
                <h5>Select Stocks</h5>
                <img src={next_icon} alt="back_icon" className="back-icon" />
              </button>
            </div>
            {/* <button
              onClick={selectStock}
              disabled={noOfStocksSelected < 2}
              style={{
                background: noOfStocksSelected < 2 ? "#9E9E9E" : "#42003F",
                justifyContent: "left",
              }}
              className="select-stock-btn"
            >
              {noOfStocksSelected < 2 ? "Select Stocks" : "Continue"}
            </button> */}
          </>
        ) : null}

        {showInvestStock ? (
          <>
            <div className="d-flex justify-content-between auto-wrap">
              <span className="bull-color font-w100 fs-12 p-0">
                Invest In Selected Stocks
              </span>
              <div className="auto-select d-flex justify-content-between">
                <span className="bull-color font-w100 p-0 mr-2">
                  Auto Invest
                </span>
                <Switch onChange={handleAutoSelect} checked={autoSelect} />
              </div>
            </div>
            <div className="amount-left">Amount Left : {getRemainingAmount()}</div>

            <div className="stock-wrapper-main">
              {Array.isArray(data) &&
                data
                  .filter((e) => e.selected)
                  .map(function (item, itemIndex, filtered) {
                    let temp = symbols.find(
                      (element) =>
                        element.symbol === item.company_relation.symbol
                    );

                    return (
                      <div
                        key={item.id}
                        className="stock-info-div flex-column align-items-start m-2"
                      >
                        <div className="d-flex justify-content-start">
                          <div>
                            <img
                              src={item.company_relation.profile_image}
                              className="stock_icon_img"
                              alt=""
                            />
                          </div>
                          <div className="stock-name-wrapper">
                            <h3>{item.company_relation.symbol}</h3>
                            {temp ? (
                              <span>
                                <img
                                  src={
                                    temp.percent_change >= 0
                                      ? stock_up_icon
                                      : stock_down_icon
                                  }
                                  alt="stock_icon"
                                  className="stock_change_img"
                                />

                                <span className="stock-percentage-change">
                                  {temp?.percent_change}%
                                </span>
                                <span className="stock-current-price">
                                  {temp?.current_price}
                                </span>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="range-input-wrapper">
                          <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            step={1}
                            min={5 * amountLeftValue}
                            max={95 * amountLeftValue}
                            value={item.coins}
                            renderThumb={(props, state) => (
                              <div {...props}>
                                {(
                                  (state.valueNow / (amountLeftValue * 100)) *
                                  100
                                ).toFixed(2) + "%"}
                              </div>
                            )}
                            onAfterChange={(value) => {
                              if (autoSelect) return false;
                              const index = data.findIndex(
                                (e) => e.id === item.id
                              );
                              if (index >= 0) {
                                let selectedAmount = 0;
                                for (const d of filtered) {
                                  if (item.id !== d.id) {
                                    selectedAmount += d.coins;
                                  }
                                }
                                // selectedAmount is sum of amounts excluding the item whose value is changing
                                const balance =
                                  amountLeftValue * 100 - selectedAmount;
                                if (value > balance) {
                                  value = balance;
                                }
                                selectedAmount += value;
                                console.log({ selectedAmount });
                                item.coins = parseInt(value);
                                const newData = Array.from(data);
                                newData[index] = Object.assign({}, item);
                                setData(newData);
                              }
                            }}
                          />
                          <span className="range-value">
                            {item.coins.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
            </div>
            {/* <button onClick={handleContinueClick} className="select-stock-btn">
              Continue
            </button> */}
            <div className="login-button-wrapper">
              <button
                type="submit"
                className="login-btn-dark inner-btn-pos"
                onClick={handleContinueClick}
                disabled={getRemainingAmount() > 0}
                style={{
                  background: (getRemainingAmount() > 0) ? "#9E9E9E" : "#42003F",
                }}
              >
                <h5>Continue</h5>
                <img src={next_icon} alt="back_icon" className="back-icon" />
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ContestStocks);
