import React, { useState } from "react";
import { ReactComponent as HomeIcon } from "./../../images/big/vuesax-bold-home.svg";
import { ReactComponent as GameIcon } from "./../../images/big/vuesax-bold-crown.svg";
import { ReactComponent as LeaderIcon } from "./../../images/big/vuesax-bold-ranking.svg";
import { ReactComponent as AccountsIcon } from "./../../images/big/vuesax-bold-frame.svg";
import learn_icon from "./../../images/icons/learn.png";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";

function AppFooter() {
  const [modalCenteredFF, setModalCenteredFF] = useState(false);
  const [modalCenteredGG, setModalCenteredGG] = useState(false);

  return (
    <div className="AppFooter-wrapper-home">
      <Modal className="fade modal-full-view" show={modalCenteredFF}>
        <Modal.Body>
          <div className="img-wrapper-mod learn">
            <div className="btn-wrapper-mod">
              <button
                onClick={() => setModalCenteredFF(false)}
                className="round-btn-close"
              >
                x
              </button>
            </div>
          </div>
          <div className="download-btn-wrapper">
            <a
              className="download-app-btn"
              href="https://cdn.bullspree.live/downloads/Bullspree.apk"
            >
              Download Now{" "}
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="fade modal-full-view" show={modalCenteredGG}>
        <Modal.Body>
          <div className="img-wrapper-mod-gg learn">
            <div className="btn-wrapper-mod">
              <button
                onClick={() => setModalCenteredGG(false)}
                className="round-btn-close"
              >
                x
              </button>
            </div>
            <a
              href="https://cdn.bullspree.live/downloads/Bullspree.apk"
              className="download-app-gg"
            ></a>
          </div>
        </Modal.Body>
      </Modal>
      <ul className="d-flex w-100 justify-content-between">
        <NavLink to="/page-home">
          <li>
            <HomeIcon />
            Home
          </li>
        </NavLink>
        <NavLink to="/my-games">
          <li>
            <GameIcon />
            My Games
          </li>
        </NavLink>
        <li onClick={() => setModalCenteredFF(true)} className="learn-link">
          <img src={learn_icon} alt="home_icon" className="learn_icon" />
          Learn
        </li>
        {/* <NavLink onClick={() => setModalCenteredFF(true)} to='/global-leaderboard'> */}
        <li onClick={() => setModalCenteredGG(true)}>
          <LeaderIcon />
          Leader
        </li>
        {/* </NavLink> */}
        <NavLink to="/page-profile">
          <li>
            <AccountsIcon />
            Account
          </li>
        </NavLink>
      </ul>
    </div>
  );
}

export default AppFooter;
