import React, { useRef,useEffect, useState } from "react";
import {
	getUserDetails
} from '../../services/AuthService';
import { connect, useDispatch, useSelector } from "react-redux";

function FileInput({ onImageSelected }) {
  const inputRef = useRef();
	const authToken = useSelector((state) => state.auth?.auth?.idToken ?? "");
  const [imgUrl, setImgUrl] = useState('')
  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };


  const onChooseImg = () => {
    inputRef.current.click();
  };
  useEffect(() => {
		getUserDetails(authToken)
    .then((response) => {
      setImgUrl(response.original_data?.user?.profile_pic)
    })
	}, [])
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
        className="input-img"
      />

      <button className="btn upload-btn-first" type="button" onClick={onChooseImg}>
        Upload
      </button>
    </div>
  );
}

export default FileInput;
