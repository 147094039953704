import { SELECT_CONTEST_ACTION, SELECT_USER_EXCHANGE_TYPE, SELECT_USER_TEAM_ID } from "../types/contest";

const initialState = {
    selected_contest: [],
    user_team_id: null,
    exchange_type: 1,
};

export default function ContestReducer(state = initialState, action) {
    switch (action.type) {
        case SELECT_CONTEST_ACTION:
            return {
                ...state,
                selected_contest: action.payload
            }

        case SELECT_USER_TEAM_ID:
            return {
                ...state,
                user_team_id: action.payload,
            };

        case SELECT_USER_EXCHANGE_TYPE:
            return {
                ...state,
                exchange_type: action.payload
            }

        default:
            return state;
    }
}
