import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import EditProfile from './jsx/pages/EditProfile';
import TermsConditions from './jsx/pages/TermsConditions';
import Guidelines from './jsx/pages/Guidelines';
import PrivacyPolicy from './jsx/pages/PrivacyPolicy';
import ContestStocks from './jsx/pages/ContestStocks';
import StockInvest from './jsx/pages/StockInvest';
import JoinContest from './jsx/pages/JoinContest';
import MyGames from './jsx/pages/MyGames';
import GlobalLeaderBoard from './jsx/pages/GlobalLeaderBoard';
import Wallet from './jsx/pages/Wallet';
import AddMoney from './jsx/pages/AddMoney';
import Tds from './jsx/pages/Tds';
import Transaction from './jsx/pages/Transaction';
import TransactionDetail from './jsx/pages/TransactionDetail';
import Withdraw from './jsx/pages/Withdraw';
import Notification from './jsx/pages/Notification';
import FPS from './jsx/pages/FPS';
import TaxDetail from './jsx/pages/TaxDetail';
import AccountVerification from './jsx/pages/AccountVerification';
import ContestDetail from './jsx/pages/ContestDetail';
import ContestDetailUpcoming from './jsx/pages/ContestDetailUpcoming';
import PriceDetail from './jsx/pages/PriceDetail';
import CouponDetail from './jsx/pages/CouponDetail';
import LiveContestDetail from './jsx/pages/LiveContestDetail';
import PriceDetailsUpcoming from './jsx/pages/PriceDetailsUpcoming';
import PrivateRoute from './jsx/layouts/PrivateRoute';
import PriceDetailLive from './jsx/pages/PriceDetailLive';
import ContestDetailReview from './jsx/pages/ContestDetailReview';
import PriceDetailContest from './jsx/pages/PriceDetailContest';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import("./jsx/pages/Login"));
const LandingPage = lazy(() => import('./jsx/pages/LandingPage'));
const ProfilePage = lazy(() => import('./jsx/pages/ProfilePage'));
const PlayInsight = lazy(() => import('./jsx/pages/PlayInsight'));
const HomePage = lazy(() => import('./jsx/pages/HomePage'));
const ContestPage = lazy(() => import('./jsx/pages/ContestPage'));

function App() {
  return (
    <div className="mobile-view">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={LandingPage} />
          <PrivateRoute exact path="/join-contest" component={JoinContest} />
          <PrivateRoute
            exact
            path="/page-stocks-invest"
            component={StockInvest}
          />
          <PrivateRoute
            exact
            path="/page-contest-stocks"
            component={ContestStocks}
          />
          <PrivateRoute exact path="/page-contest" component={ContestPage} />
          <PrivateRoute exact path="/page-home" component={HomePage} />
          <PrivateRoute
            exact
            path="/page-play_insight"
            component={PlayInsight}
          />
          <PrivateRoute
            exact
            path="/page-privacy_policy"
            component={PrivacyPolicy}
          />
          <PrivateRoute exact path="/page-guidelines" component={Guidelines} />
          <PrivateRoute
            exact
            path="/page-terms_conditions"
            component={TermsConditions}
          />
          <PrivateRoute
            exact
            path="/edit-profile-image"
            component={EditProfile}
          />
          <PrivateRoute exact path="/page-profile" component={ProfilePage} />
          <PrivateRoute exact path="/page-register" component={SignUp} />
          <PrivateRoute
            exact
            path="/page-forgot-password"
            component={ForgotPassword}
          />
          <PrivateRoute exact path="/my-games/:t1/:t2" component={MyGames} />
          <PrivateRoute exact path="/my-games" component={MyGames} />
          <PrivateRoute exact path="/wallet-page" component={Wallet} />
          <PrivateRoute exact path="/add-money" component={AddMoney} />
          <PrivateRoute
            exact
            path="/global-leaderboard"
            component={GlobalLeaderBoard}
          />
          <PrivateRoute exact path="/tds" component={Tds} />
          <PrivateRoute exact path="/transaction" component={Transaction} />
          <PrivateRoute
            exact
            path="/transaction-detail"
            component={TransactionDetail}
          />
          <PrivateRoute exact path="/withdraw-page" component={Withdraw} />
          <PrivateRoute
            exact
            path="/notification-page"
            component={Notification}
          />
          <PrivateRoute exact path="/fps-page" component={FPS} />
          <PrivateRoute exact path="/tax-detail" component={TaxDetail} />
          <PrivateRoute
            exact
            path="/account-verification"
            component={AccountVerification}
          />
          <PrivateRoute
            exact
            path="/contest-detail"
            component={ContestDetail}
          />
          <PrivateRoute
            exact
            path="/contest-detail-review"
            component={ContestDetailReview}
          />
          <PrivateRoute
            exact
            path="/contest-detail-live"
            component={LiveContestDetail}
          />
          <PrivateRoute
            exact
            path="/contest-detail-upcoming"
            component={ContestDetailUpcoming}
          />
          <PrivateRoute exact path="/price-detail" component={PriceDetail} />
          <PrivateRoute
            exact
            path="/price-detail-upcoming"
            component={PriceDetailsUpcoming}
          />
          <PrivateRoute
            exact
            path="/price-detail-live"
            component={PriceDetailLive}
          />
          <PrivateRoute exact path="/coupon-detail" component={CouponDetail} />
          <PrivateRoute exact path="/price-detail-contest" component={PriceDetailContest} />

        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
