export const calculateTimeLeft = (contestStartTime) => {
  let startDateTime = new Date(contestStartTime);
  let currentDateTime = new Date();
  let remainingDateTime = startDateTime.getTime() - currentDateTime.getTime();
  if (startDateTime <= currentDateTime) {
    return 0;
  } else {
    return remainingDateTime;
  }
};
