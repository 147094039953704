import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  OTP_SCREEN_ACTION,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_PICTURE_FAILED,
  UPDATE_PROFILE_PICTURE_SUCCESS,
} from "../types/auth";

const initialState = {
  showOtp: false,
  auth: null,
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_CONFIRMED_ACTION:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
        errorMessage: "",
        successMessage: "Signup Successfully Completed",
        showLoading: false,
      };
    case LOGIN_CONFIRMED_ACTION:
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
        errorMessage: "",
        successMessage: "",
        showLoading: false,
      };
    case OTP_SCREEN_ACTION:
      return {
        ...state,
        showOtp: true,
      };
    case LOGOUT_ACTION:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        auth: {
          email: "",
          idToken: "",
          localId: "",
          expiresIn: "",
          refreshToken: "",
        },
      };
    case SIGNUP_FAILED_ACTION:
    case LOGIN_FAILED_ACTION:
    case UPDATE_PROFILE_FAILED:
    case UPDATE_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: "",
        showLoading: false,
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
}
