import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from "react-redux";
import add_money_card from './../../images/icons/Mask Group 338.png';
import spree_coin from './../../images/icons/spreecoin.svg';
import useRazorpay from "react-razorpay";
import axios from "axios";
import back_icon from './../../images/icons/back.svg';
import back_dark_icon from './../../images/icons/know more down arrow.svg';
import { Modal } from "react-bootstrap";
import prmo_img from './../../images/icons/animation_500_lggg437f.gif';
import { Link, useHistory } from "react-router-dom";
import close_icon from './../../images/icons/CLOSE.svg';
import copy_icon from './../../images/icons/Icon ionic-md-copy.svg';
import Countdown from 'react-countdown';
import { loginConfirmedAction } from '../../store/actions/AuthActions';

function AddMoney(props) {
    const Razorpay = useRazorpay();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.auth.user);
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const walletData = useSelector((state) => state.auth?.auth?.wallet);
    const [promoInputValue, setPromoInputValue] = useState();
    const [messageForPromoValid, setMessageForPromoValid] = useState();
    const [showMessage, setShowMessage] = useState(false);
    const [couponData, setCouponData] = useState()
    const [modalCenteredMM, setModalCenteredMM] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false)
    const [walletMoney, setWalletMoney] = useState(0);
    let history = useHistory();

    const handlePromocodeInput = (e)=>{
        setPromoInputValue(e.target.value.toUpperCase())
        setShowMessage(false);
    }
    

    const handlePayment = () => {

        if (walletMoney >= 100) {
            let coupon_id = couponData.find(item => item.coupon_code.toUpperCase() === promoInputValue )
            let couponCode = coupon_id ? coupon_id.id : promoInputValue;
            let config = {
                method: 'get',
                url: `https://api.bullspree.com/api/v1/generate-order-id?team_id&amount=${walletMoney}&coupon_id =${couponCode}`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            };

            axios(config)
                .then(function (response) {
                    let razorOrderData = response.data
                    if (response.data.success) {
                       let finalAmmount = Number(razorOrderData.original_data.amount)/(1000)
                        const RazorpayOptions = {
                            key: "rzp_live_kmKrcohcMv99Mj",
                            amount: finalAmmount,
                            currency: "INR",
                            name: "Bullspree",
                            description: "Test Transaction",
                            order_id: razorOrderData.original_data.rayzorOrder,
                            handler: (res) => {
                                console.log({ res, user }, 'test for razor');
                                const { razorpay_signature, razorpay_payment_id, razorpay_order_id } = res;
                                var data = {
                                    payment_status: "SUCCESS",
                                    payment_id: razorpay_payment_id,
                                    razorpay_signature,
                                    team_id: '',
                                    coupon_id:couponCode ? couponCode : null,
                                    order_id: res.razorpay_order_id,
                                    payment_response: {
                                        razorpay_payment_id,
                                        razorpay_signature,
                                        razorpay_order_id
                                    }
                                };

                                var config = {
                                    method: 'post',
                                    url: 'https://api.bullspree.com/api/v1/add-money-in wallet',
                                    headers: {
                                        'Authorization': `Bearer ${authToken}`
                                    },
                                    data: data
                                };

                                axios(config)
                                    .then(function (response) {
                                        console.log(response.data, 'test resp of money');
                                        if (response.data.success) {
                                            props.history.push('/wallet-page');
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            },
                            prefill: {
                                name: user.full_name,
                                email: user.email,
                                contact: user.phone,
                            },
                            notes: {
                                address: "Razorpay Corporate Office",
                            },
                            theme: {
                                color: "#3399cc",
                            },
                        };
                        console.log(RazorpayOptions,'test razor options')
                        const rzpay = new Razorpay(RazorpayOptions);
                         rzpay.open();
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            setErrorMsg(true)
        }
    };

    const handleBestOfferBtnClick = async()=>{
        // Find best offer from available coupons and set it
        // let bestOffer = couponData.find( (item) => item.is_best_offer)
        // bestOffer?.coupon_code && setPromoInputValue(bestOffer?.coupon_code.toUpperCase());

        // Using get method
        let coupon_id = couponData.find(item => item.coupon_code.toUpperCase() === promoInputValue )
        let couponCode = coupon_id ? coupon_id.id : promoInputValue;
        let res; 
        var config = {
            method: 'post',
            url: 'https://api.bullspree.com/api/v1/auto-apply-coupon',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            params: { 
                amount : walletMoney,
                coupon_category : 1,
                coupon_id: couponCode, 
            }
        };
        await axios(config).then((response) =>{
            console.log("response from best offer button =", response);
            if(response.data.success){
                setPromoInputValue(response.data.original_data.coupon.coupon_code)
                setModalCenteredMM(false)
                setShowMessage(true);
                setMessageForPromoValid("Promocode valid");
            }else{
                setShowMessage(true);
                setMessageForPromoValid(res?.data?.message);
            }
            
        }).catch(error =>{
            console.log(error);
            setShowMessage(true);
            setMessageForPromoValid(error);
        });   
    }

    const handleApplyBtnClick = async (e)=>{
        let coupon_id = couponData.find(item => item.coupon_code.toUpperCase() === promoInputValue )
        let couponCode = coupon_id ? coupon_id.id : promoInputValue;
        let res; 
        var config = {
            method: 'post',
            url: 'https://api.bullspree.com/api/v1/apply-coupon',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            params: {
                coupon_id: couponCode, 
                amount : walletMoney
            }
        };
        await axios(config).then(response =>{
            res = response;
            if(res?.data?.success){
                setModalCenteredMM(false)
                setShowMessage(true);
                setMessageForPromoValid("Promocode valid");
            }else{
                setShowMessage(true);
                setMessageForPromoValid(res?.data?.message);
            }
        }).catch(error =>{
            console.log(error);
            setShowMessage(true);
            setMessageForPromoValid(error);
        });   
 
    }
    

    const handleRemoveBtnClick = (e)=>{
        setPromoInputValue("");
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <span>No more available</span>;
		}
        if(days > 0){
            return <span className="text-danger fs-12">{days} Days left</span>;
        }else if(hours > 0){
            return <span className="text-danger fs-12">{hours}Hr {minutes}Min</span>;
        }else{
            return <span className="text-danger fs-12">{seconds} seconds left</span>;
        }
        
	};

    const getStyles = (element,index)=>{
        const reminder = index%3;
        const fissiblecase = element +"-"+ reminder;
        let resultStyles = {};
        switch(fissiblecase){
            case "content-bg-0":
                resultStyles={
                    background: "#D9F0FF"
                }
                break;
            case "content-bg-1":
                resultStyles={
                    background: "#EDE2FF"
                }
                break;
            case "content-bg-2":
                resultStyles={
                    background: "#FDEFD9"
                }
                break;
            case "heading-0":
                resultStyles={
                    color: "#3E9DDB"
                }
                break;
            case "heading-1" :
                resultStyles={
                    color: "#8953DE"
                }
                break;
            case "heading-2" :
                resultStyles={
                    color: "#DB8521"
                }
                break;
            case "button-0" :
                resultStyles={
                    background: "#3E9DDB"
                }
                break;
            case "button-1" :
                resultStyles={
                    background: "#8953DE"
                }
                break;
            case "button-2":
                resultStyles={
                    background: "#DB8521"
                }
                break;
            default:
                resultStyles={}
        }
        return resultStyles;
    }

    useEffect(() => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-user-wallets?page=1&bonus=false',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.original_data.wallet)
                dispatch(loginConfirmedAction(response.data.original_data));
                // setWalletData(response.data.original_data.wallet)
            })
            .catch(function (error) {
                console.log(error);
            });

            getOffers()
    }, []);

    const getOffers = () => {
        var data = '';

        var config = {
            method: 'get',
            url: `https://api.bullspree.com/api/v1/get-coupons?amount=${walletMoney}&coupon_category=1&coupon_type=&user_level=0&contest_id=&coupon_status=3`,
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setCouponData(response.data.original_data.coupons)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handelPromoCode = () => {
        setModalCenteredMM(true)
        setPromoInputValue("");
    }

    return (
        <>
            <div className='top-head-logo mb-25'>
                <div>
                    <img
                        src={back_icon}
                        alt="back_icon"
                        onClick={() => history.goBack()}
                        className="back-icon"
                    />
                </div>
                <div>
                    <h4 className='m-0 noti-head'>Get Spree Coins</h4>
                </div>
            </div>
            <div className='wallet-detail-wrapper'>

                <div className='wallet-header-card adjust-card-box'>
                    <div className='d-flex'>
                        <img
                            src={spree_coin}
                            alt="wallet_icon"
                            className="spree-wallet-add"
                        />
                        <span className='wallet-price'>{walletData?.total_winning_coins}</span>
                        <span className='wallet-price-type'>Current Balance</span>
                    </div>
                    <div className='throphy-wraper'>
                        <img
                            height={120}
                            src={spree_coin}
                            alt="wallet_icon"
                            className='wallet-trophy'
                        />
                    </div>

                </div>
            </div>
            <div className='profile-container p-2 set-m-0-small'>
                <div className='add-input-wrapper'>
                    <img
                        src={spree_coin}
                        alt="wallet_icon"
                        className=""
                    />
                    <input type='number' placeholder='Enter Coins' value={walletMoney} onChange={(e) => setWalletMoney(e.target.value)} />
                    {
                        errorMsg ?
                            <span className='err-msg add-err-msg'>Add Rs. 100 or more</span>
                            : null
                    }
                </div>

                <div className='p-2 d-flex justify-content-between m-3'>
                    <div onClick={() => setWalletMoney(Number(walletMoney) + 100)} className='position-relative d-flex'>
                        <span className='add-money-btn'>+100</span>
                        <img
                            src={add_money_card}
                            alt="add_money_card"
                            className="add-money-card"
                        />
                    </div>
                    <div onClick={() => setWalletMoney(Number(walletMoney) + 500)} className='position-relative d-flex'>
                        <span className='add-money-btn'>+500</span>
                        <img
                            src={add_money_card}
                            alt="add_money_card"
                            className="add-money-card"
                        />
                    </div>
                    <div onClick={() => setWalletMoney(Number(walletMoney) + 1000)} className='position-relative d-flex'>
                        <span className='add-money-btn'>+1000</span>
                        <img
                            src={add_money_card}
                            alt="add_money_card"
                            className="add-money-card"
                        />
                    </div>
                </div>
                <div>
                    
                    {Array.isArray(couponData) &&
                        couponData.length > 0 ?
                    <button onClick={() => handelPromoCode()} className='promo-btn'>
                        <div>
                            <img
                                src={prmo_img}
                                alt="prmo_img"
                                className="prmo_img"
                            />
                            Apply Promocode
                        </div>
                        <div>
                            <img
                                src={back_dark_icon}
                                alt="add_money_card"
                                className="back_icon rotated"
                            />
                        </div>
                    </button>
                    : null }
                    <Modal className="fade bottom-modal promo-modal" show={modalCenteredMM}>
                        <Modal.Body className='promo-code-body'>
                            <div className='d-flex justify-content-between'>
                                <h4>Apply Promocode</h4>
                                <h5 onClick={() => {setModalCenteredMM(false); setShowMessage(false);}}>
                                    <img
                                        src={close_icon}
                                        alt="back_icon"
                                        className="back-icon"
                                    />
                                </h5>
                            </div>
                            <div className='promocode-input-parent'>
                                <input className='promocode-input' type='text' value={promoInputValue} onChange={handlePromocodeInput} placeholder='Enter Promocode'></input>
                                {
                                    promoInputValue && ( !showMessage ? <button id="promocode-apply-button" onClick={handleApplyBtnClick}>Apply</button> : <button id="promocode-apply-button" onClick={handleRemoveBtnClick}>Remove</button>)
                                }
                                {
                                    promoInputValue && showMessage && <><br/><p className='helper-text-error'>{messageForPromoValid}</p></>
                                }
                            </div>
                            
                            <div className='d-flex'>
                                <button onClick={handleBestOfferBtnClick}>Apply Best Offer For Me</button>
                            </div>
                            <div className='seperator-div'></div>                            
                            <p>Other Offer for you</p>
                            {Array.isArray(couponData) &&
                                couponData.map(function (d, i) {
                                    return (
                                        <div>
                                            <div className='offer-wrapper'>
                                                <div className='offer-content' style={getStyles("content-bg", i)}>
                                                    <div className='d-flex justify-content-between align-items-baseline mb-1'>
                                                        <h5 className='w-50' style={getStyles("heading", i)}>{d?.title}</h5>
                                                        <button onClick={() => {setPromoInputValue(d?.coupon_code.toUpperCase()); setShowMessage(false);}} style={getStyles("button", i)} className='w-50 offer-btn'>{d?.coupon_code} <img
                                                            src={copy_icon}
                                                            alt="copy_icon"
                                                            className="ml-2 copy_icon"
                                                        /></button>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className='col-md-4'>Add {d.min_coin_requirement} or more</span>
                                                        <span className='col-md-4 text-center'>
                                                        <Countdown
                                                            date={new Date(d?.end_date)}
                                                            renderer={renderer}
                                                        />
                                                        </span>
                                                        <span className='col-md-4'>{d?.coupon_left}</span>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <Link to ='coupon-detail'>
                                                    <button className='view-tnc'>View T&C</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                        </Modal.Body>

                    </Modal>
                </div>

                {
                   messageForPromoValid === "Promocode valid" && !modalCenteredMM ? <div className='applied-promo-card'>
                   <div className='grey-area'>
                       <p>{promoInputValue}</p>
                       <button onClick={()=> setMessageForPromoValid("Promocode invalid")}>Remove Code</button>
                   </div>
                   <div className='green-area'> 
                       <p>You have saved Rs.{
                        (couponData.find(item => item.coupon_code === promoInputValue)?.reward_cashback_coins)/10
                        }</p>
                   </div>
                               
               </div> : null

                }
                
                <button
                    onClick={handlePayment}
                    className="select-stock-btn"
                >
                    Continue
                </button>
            </div>


        </>

    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(AddMoney);