import { SELECT_CONTEST_ACTION, SELECT_USER_EXCHANGE_TYPE, SELECT_USER_TEAM_ID } from "../types/contest";

export const selectContestAction = (data) =>({
    type: SELECT_CONTEST_ACTION,
    payload: data
})

export const selectUserTeamAction = (userTeamId)=>({
    type: SELECT_USER_TEAM_ID,
    payload: userTeamId
})

export const selectExchangeTypeAction = (exchangeType)=>({
    type: SELECT_USER_EXCHANGE_TYPE,
    payload: exchangeType
})
