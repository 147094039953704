import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import spree_coin from './../../images/icons/spreecoin.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import back_icon from './../../images/icons/back.svg';

function Transaction(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [post, setPosts] = useState()
    const [myData, setMyData] = useState()
	let history = useHistory();


    const getMorePost = async () => {
        var config = {
            method: 'get',
            url: ' https://api.bullspree.com/api/v1/get-user-wallets?page=1&wallet_transaction_type=',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        axios(config)
            .then(function (response) {
                var my_data = response.data.original_data.wallet_transaction;
                console.log(my_data, 'test my_data')
                setMyData(my_data)
                const newPosts = response.data.original_data.wallet_transaction.data;
                console.log(newPosts, 'newposts')
                setPosts((post) => [post, ...newPosts]);

            })
            .catch(function (error) {
                console.log(error);
            });


    };


    useEffect(() => {
        getMorePost()
    }, [])


    let page = 1;
    const fetchData = (setItems, items) => {
        var config = {
            method: 'get',
            url: ` https://api.bullspree.com/api/v1/get-user-wallets?page=1&wallet_transaction_type=`,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios(config)
            .then((res) => {
                console.log(res.data.original_data.wallet_transaction.data, 'test this res')
                setItems([...items, ...res.data.original_data.wallet_transaction.data]);
                page = page + 1;
            });
    };

    const refresh = (setItems) => { };


    const [items, setItems] = React.useState([]);

    useEffect(() => {
        fetchData(setItems, items)
    }, [])
    return (
        <>
            <div className='top-head-logo mb-25'>
				<div>
					<img
						src={back_icon}
						alt="back_icon"
						onClick={() => history.goBack()}
						className="back-icon"
					/>
				</div>
				<div>
					<h4 className='m-0 noti-head'>TRANSACTION HISTORY</h4>
				</div>
			</div>
            <div style={{ height: "90%" }} className='profile-container overflow-auto'>
                <div style={{ minHeight: "100%" }}>
                    <InfiniteScroll
                        dataLength={items.length} //This is important field to render the next data
                        next={() => {
                            fetchData(setItems, items);
                        }}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={refresh}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={50}
                    >
                        {
                            Array.isArray(items) && items.map((user, id) => {
                                return (
                                    <Link to='transaction-detail'>
                                    <div className='d-flex justify-content-between w-100 bb transact-p'>
                                        <div>
                                            <h4>{user.wallet_transaction_message}</h4>
                                            <span>{user.wallet_transaction_date}</span>
                                        </div>
                                        <div className= {user.transaction_type === "CREDIT" ?  "transaction-amount-wrap-plus'" : "transaction-amount-wrap-minus"}>
                                            {user.transaction_type === "CREDIT" ?  <span  className='tran-price'>+</span> : <span  className='tran-price'>-</span>}
                                            <img
                                                src={spree_coin}
                                                alt="wallet_icon"
                                                className="smallest-spree"
                                            />
                                            <span className='tran-price'>{user.coins}</span>
                                        </div>
                                    </div>
                                    </Link>
                                )
                            })
                        }

                    </InfiniteScroll>

                </div>

            </div>

        </>
    );
}


const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Transaction);