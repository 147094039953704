import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import spree_coin from './../../images/icons/spreecoin.svg';
import i_icon from './../../images/icons/i icon gray.svg';
import { useHistory } from "react-router-dom";
import back_icon from './../../images/icons/back arrow.svg';
import { Card, Modal, Carousel, Button } from 'react-bootstrap'
import parse from "html-react-parser";
import { Link } from 'react-router-dom';

//

function Tds(props) {
    const [modalCentered, setModalCentered] = useState(false);
    const [modalCenteredPP, setModalCenteredPP] = useState(false);
    const [mainData, setMainData] = useState('')
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [slides, setSlides] = useState([]);
    const [walletData, setWalletData] = useState([]);
	let history = useHistory();


    useEffect(() => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: ' https://api.bullspree.com/api/v1/get-user-wallet-spree-coins',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.original_data.tds_carousol, 'test tds data');
                let walletCarouselDetail = response.data.original_data
                let carouselDetail = (response.data.original_data.tds_carousol);
                setWalletData(walletCarouselDetail)
                setSlides(carouselDetail);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleWallet = () => {
        console.log("i was clicked wallet")
        if (walletData?.total_user_withdrawable_coins >= 1000) {
            props.history.push('/withdraw-page')
        }

        else {
            setModalCenteredPP(true)
        }
    }
    return (
        <>
            <div className='home-page-container'>
            <div className='top-head-logo mt-0 bg-white posi-fix-tds'>
				<div>
					<img
						src={back_icon}
						alt="back_icon"
						onClick={() => history.goBack()}
						className="back-icon mr-2"
					/>
				</div>
				<div>
					<h4 className='m-0 noti-head text-bull'>MY SPREE COINS</h4>
				</div>
			</div>

                <div className='home-page-content h-auto po-fi-pad'>
                    <div className='tds-card-wrapper'>
                        <div className='tds-card-header'>
                            <h4>Net Winning : </h4><span>{walletData?.total_net_winning_coins}</span>
                        </div>
                        <div className='tds-card-content'>
                            <div className='margin-fix-3'>
                                <img
                                    src={spree_coin}
                                    alt="wallet_icon"
                                    className="smallest-spree"
                                />
                                <span>{walletData?.total_investment_coins}</span>
                                <span className='d-block negative-margin'>Investment</span>
                            </div >
                            <div className='margin-fix-3'>
                                <img
                                    src={spree_coin}
                                    alt="wallet_icon"
                                    className="smallest-spree"
                                />
                                <span>{walletData?.total_winning_coins}</span>
                                <span className='d-block negative-margin'>Total Winnings</span>
                            </div>
                        </div>
                        <div className='tds-card-footer'>
                            <div className='mt-0 d-flex justify-content-between'>
                                <div>
                                    <h4 className='m-0'>{walletData?.after_tax_deduct_coins}</h4>
                                    <span className='d-block d-inline-imp'>After Tax deducted</span>
                                    <Link to = 'tax-detail'>
                                    <img
                                        src={i_icon}
                                        alt="wallet_icon"
                                    />
                                    </Link>
                                </div>
                                <div >
                                    <button onClick={() => setModalCentered(true)} className='wallet-btn'>Withdraw now</button>
                                </div>

                                <Modal className="fade bottom-modal" show={modalCentered}>
                                    <Modal.Body>
                                        <p>We encourage you to not withdraw frequently and have a 30% extra amount to play contests</p>
                                    </Modal.Body>
                                    <Modal.Footer className='justify-content-center modal-foot-pad'>
                                        <Link to='withdraw-page'>
                                            <button onClick={() => handleWallet()} className='tds-modal-now-btn'>
                                                Withdraw Now
                                            </button>
                                        </Link>
                                        <Button
                                            onClick={() => setModalCentered(false)}
                                            className="tds-modal-later-btn wallet-btn"
                                        >
                                            Withdraw Later
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 className='wallet-heading text-bull p-20'>What is TDS ?</h4>
                        <Card>
                            <Card.Body className='p-3'>
                                <Carousel controls={false} indicators={false}>
                                    {slides?.map((carousel, i) => (
                                        <Carousel.Item key={i}>
                                            <Carousel.Caption>
                                                <span>{parse(carousel?.tds_content)}</span>
                                            </Carousel.Caption>
                                            <img
                                                className='d-block w-100'
                                                src={carousel?.tds_image}
                                                alt='tds-carousel'
                                            />

                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Card.Body>
                        </Card>

                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Tds);