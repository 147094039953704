import { React, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import stock_change_icon from './../../images/icons/vuesax-linear-danger.svg';
import stock_ppl from './../../images/icons/Icon material-people (1).svg';
import axios from 'axios';
import HeaderStock from '../layouts/HeaderStock';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

function StockInvest(props) {
    const [data, setData] = useState([])
    const [value, setValue] = useState([30, 60]);
    const [timer, setTimer] = useState()
    const [symbols, setSymbols] = useState([])
    const contestId = useSelector((state) => state.contest.selected_contest.id);
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');

    useEffect(() => {
        var config = {
            method: 'get',
            url: `https://api.bullspree.com/api/v1/get-contests-company-list?contest_id=${contestId}`,
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };
        axios(config)
            .then(function (response) {
                console.log(response, 'test response')
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [authToken, contestId])

    const addCardElement = () => {
        console.log("card was clicked")
    };
    const selectStock = () => {
        props.history.push('/page-stocks-invest');
    }

    return (
        <>
            <div className='home-page-container'>
                <HeaderStock timerTime={timer} />
                <div className='stock-wrapper-main'>
                    <h4>Automobiles</h4>
                    {
                        data.map(function (item, i) {
                            return (
                                <div className='invest-wrapper'>
                                    <div id={i} className='stock-info-div'>
                                        <div className='d-flex'>
                                            <div>
                                                <img
                                                    src={item.company_relation.profile_image}
                                                    className='stock_icon_img'
                                                    alt=''
                                                />
                                            </div>
                                            <div className='stock-name-wrapper'>
                                                <h3>{item.company_relation.symbol}</h3>
                                                <span>
                                                    <img
                                                        src={stock_change_icon}
                                                        alt="stock_icon"
                                                        className='stock_change_img'
                                                    />
                                                    2.36 %</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='slider-range-wrapper'>
                                        <RangeSlider
                                            className="single-thumb"
                                            defaultValue={[0, 50]}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={true}
                                        />                                        <div>
                                            <span>{value}</span>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>

                <button onClick={selectStock} className='select-stock-btn'>Continue</button>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(StockInvest);