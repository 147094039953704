
import {
    formatError,
    generateOtp,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    getImages,
    getUserWallet,
    updateUserProfile,
    updateUserProfilePicture
} from '../../services/AuthService';
import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  UPDATE_PROFILE_BEGIN,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_PICTURE_BEGIN,
  UPDATE_PROFILE_PICTURE_FAILED,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
} from "../types/auth";

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export const logout = (history) => (dispatch) => {
  localStorage.removeItem("userDetails");
  dispatch({ type: LOGOUT_ACTION });
  history.push("/");
};

export function GetImagesAction() {
    return (dispatch) => {
        getImages()
        .then((response) => {
            return response.data.original_data.carousels
        })
    };
}

export function RegisterdNumberAction(phone) {
    return (dispatch) => {
        generateOtp(phone)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export const checkAutoLoginAction = (history)=>(dispatch, getState) =>{
    const state = getState();
    console.log({state});
    const tokenDetails = state.auth?.auth?.idToken?.length;
    if (!tokenDetails) {
        dispatch(logout(history));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));
}

export const getUserWalletDataAction = () => (dispatch, getState) => {
    const state = getState();
    const authToken =state.auth?.auth?.idToken ?? '';
    getUserWallet(authToken)
        .then((response) => {
            dispatch(loginConfirmedAction(response.data.original_data));
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(loginFailedAction(errorMessage));
        });
};

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const updateProfile = (data) => (dispatch, getState) => {
  const state = getState();
  const authToken = state.auth?.auth?.idToken ?? "";

  dispatch({ type: UPDATE_PROFILE_BEGIN });
  updateUserProfile(authToken, data)
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: res.data.original_data,
      });
    })
    .then((err) => {
      dispatch({ type: UPDATE_PROFILE_FAILED, payload: err });
    });
};

export const updateProfilePicture = (data) => (dispatch, getState) => {
  const state = getState();
  const authToken = state.auth?.auth?.idToken ?? "";

  dispatch({ type: UPDATE_PROFILE_PICTURE_BEGIN });
  updateUserProfilePicture(authToken, data)
    .then((res) => {
      console.log("Update profile response", res.data);
      dispatch({
        type: UPDATE_PROFILE_PICTURE_SUCCESS,
        payload: res.data.original_data,
      });
    })
    .then((err) => {
      dispatch({ type: UPDATE_PROFILE_PICTURE_FAILED, payload: err });
    });
};