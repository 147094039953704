import React, { useEffect, useState } from 'react'
import { connect, useSelector } from "react-redux";
import axios from "axios";
import back_icon from './../../images/icons/back.svg';
import Countdown from 'react-countdown';
import { useHistory } from "react-router-dom";

function CouponDetail(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [couponData, setCouponData] = useState()
    let history = useHistory();

    useEffect(() => {
        var data = '';
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-coupon-details?coupon_id=551',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };


        axios(config)
            .then(function (response) {
                setCouponData(response.data.original_data.coupon)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        <>
            <div className='cd-wrapper'>
                <div className='top-head-logo'>
                    <div>
                        <img
                            src={back_icon}
                            alt="back_icon"
                            onClick={() => history.goBack()}
                            className="big-back-icon"
                        />
                    </div>

                </div>
                <div>
                    <h4 className='m-0 cd-head'>Get Spree Coins</h4>
                    <span className='coupon-left'>Coupon Left - {couponData?.coupon_left}</span>
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='expiry-date'>
                        Expiry -05 July, 2022
                    </div>
                </div>

                <div className='wallet-detail-wrapper'>


                </div>
            </div>
            <div className='profile-container p-2 set-m-0-small'>
                <div>
                    {/* <span
                        className="counter-contest"
                    >
                        20:45:08
                    </span> */}
                    <Countdown
                        date={Date.now() + couponData?.end_date}
                        renderer={props => <div>{props.total}</div>}
                    />

                </div>
                <div className='tnc-wrapper'>
                    <div>
                        <p className='tnc-head'>TERMS & CONDITIONS</p>
                        {Array.isArray(couponData?.display_terms_conditions) && couponData?.display_terms_conditions.map(function (d, i) {
                            return (
                                <li className='tnc-conditons'>* {d}</li>
                            )
                        })
                        }
                    </div>
                    <div>
                        <p className='tnc-head'>Offer Duration</p>
                        <p className='tnc-conditons'>{couponData?.offer_duration}</p>
                    </div>

                    <p className='tnc-head'>Rewards</p>
                    <p className='tnc-conditons'>{couponData?.reward_description}</p>
                </div>

            </div>


        </>

    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(CouponDetail);