import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import back_icon from "./../../images/icons/back.svg";
import { useHistory } from "react-router-dom";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
import { Button, Form } from "react-bootstrap";
import {
  updateProfile,
  updateProfilePicture,
} from "../../store/actions/AuthActions";
import next_icon from './../../images/icons/arrow next.svg';

//
function EditProfile(props) {
  const history = useHistory();
  const formRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.auth?.user);
  const [image, setImage] = useState(user?.profile_pic);
  const [formData, setFormData] = useState({
    full_name: Array.isArray(user.user_kyc_names) &&
      user.user_kyc_names.length
      ? user.user_kyc_names[0].name
      : user.full_name,
      email: user.email
  })
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState(user?.profile_pic);

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");
     const newUrl = dataURL.replace("data:image/jpeg;base64,", "")
      dispatch(
        updateProfilePicture({
          profile_pic: newUrl,
        })
      );
      setImgAfterCrop(dataURL);
      setCurrentPage("img-cropped");
    };
  };

  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };

  const handleInputChange = e=>{
    const {target: {name, value}} = e;
    const newFormData = Object.assign(formData, {[name]: value});
    setFormData(newFormData);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
    console.log("Update profile data", formData);
    dispatch(updateProfile(formData));
  };

  return (
    <>
      <div className="top-head-logo mb-25">
        <div>
          <img
            src={back_icon}
            alt="back_icon"
            onClick={() => history.goBack()}
            className="back-icon"
          />
        </div>
        <div>
          <h4 className="m-0 noti-head">EDIT PROFILE</h4>
        </div>
      </div>

      <div className="profile-container h-100 overflow-auto">
        <div className="container">
          {currentPage === "crop-img" ? (
            <ImageCropper
              image={image}
              onCropDone={onCropDone}
              onCropCancel={onCropCancel}
            />
          ) : (
            <>
              <div className="crop-img-wrapper">
                <div>
                  <img
                    src={imgAfterCrop}
                    className="cropped-img"
                    alt="Profile"
                  />
                </div>
                <FileInput
                  setImage={setImage}
                  onImageSelected={onImageSelected}
                />
              </div>
            </>
          )}
          {console.log(Boolean(user.user_kyc_names?.length),'user.user_kyc_names.name')}
          <Form onSubmit={handleFormSubmit} ref={formRef}>
            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                name="full_name"
                type="text"
                placeholder="Full Name"
                onChange={handleInputChange}
                value={formData.full_name}
                disabled={Boolean(user.user_kyc_names?.length)}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                placeholder="Email"
                defaultValue={user.email}
              />
            </Form.Group> */}
            <div className="login-button-wrapper">
							<button type="submit" className="login-btn-dark lo-fi">
								<h5> Update Profile</h5>
								<img
									src={next_icon}
									alt="back_icon"
									className="back-icon"
								/>
							</button>
						</div>
            {/* <Button className="updateProfile-btn" type="submit">
              Update Profile
            </Button> */}
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
