import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import AppFooter from '../layouts/AppFooter';
import spree_coin from './../../images/icons/spreecoin.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

function GlobalLeaderBoard(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [post, setPosts] = useState()
    const [myData, setMyData] = useState()


    const getMorePost = async () => {
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/global-leader-board-by-user-level?user_level=1',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        axios(config)
            .then(function (response) {
                var my_data = response.data.original_data.my_leader_board;
                console.log(my_data, 'test my_data')
                setMyData(my_data)
                const newPosts = response.data.original_data.user_leader_board.data;
                console.log(newPosts, 'newposts')
                setPosts((post) => [post, ...newPosts]);

            })
            .catch(function (error) {
                console.log(error);
            });


    };


    useEffect(() => {
        getMorePost()
    }, [])


    let page = 1;
    const fetchData = (setItems, items) => {
        var config = {
            method: 'get',
            url: `https://api.bullspree.com/api/v1/global-leader-board-by-user-level?user_level=1_page=${page}&_limit=10`,
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios(config)
            .then((res) => {
                console.log(res.data.original_data.user_leader_board.data,'test this res')
                setItems([...items, ...res.data.original_data.user_leader_board.data]);
                page = page + 1;
            });
    };

    const refresh = (setItems) => { };


    const [items, setItems] = React.useState([]);

    useEffect(() => {
        fetchData(setItems, items)
    }, [])
    return (
        <>
            <div className='header-bull h-20'>

            </div>
            <div className='profile-container overflow-auto'>
                <div className='d-flex m-3 p-3 justify-content-between'>
                    <div>
                        <h4 className='mb-0'>LEADER</h4>
                    </div>
                    <div>
                        <span>total winnings</span>
                    </div>
                </div>

                <div className='d-flex justify-content-between p-3 w-100 bb my-data-class'>
                    <div className='d-flex justify-content-start w-80'>
                        <div className='w-10'>
                            <span className='leader-rank text-white'>{myData?.my_rank}</span>
                        </div>
                        <div className='w-90'>
                            <img
                                src={myData?.profile_pic}
                                alt=""
                                className="img-fluid sm-logo mt-1 leader-image"
                            />
                            <span className='leader-name w-100 text-white'>{myData?.full_name}</span>
                        </div>
                    </div>

                    <div className='w-20 d-flex'>
                        <img
                            src={spree_coin}
                            alt="wallet_icon"
                            className="img-fluid sm-logo mt-1"
                        />
                        <span className='leader-name text-white'>{myData?.total_earning}</span>
                    </div>
                </div>
                <div style={{ minHeight: "100vh" }}>
                    <InfiniteScroll
                        dataLength={items.length} //This is important field to render the next data
                        next={() => {
                            fetchData(setItems, items);
                        }}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={refresh}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={50}
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
                        }
                    >
                        {
                            Array.isArray(items) && items.map((user, id) => {
                                return (
                                    <div className='d-flex justify-content-between p-3 w-100 bb'>
                                        <div className='d-flex justify-content-start w-80'>
                                            <div className='w-10'>
                                                <span className='leader-rank'>{user?.my_rank}</span>
                                            </div>
                                            <div className='w-90'>
                                                <img
                                                    src={user?.profile_pic}
                                                    alt=""
                                                    className="img-fluid sm-logo mt-1 leader-image"
                                                />
                                                <span className='leader-name w-100'>{user?.full_name}</span>
                                            </div>
                                        </div>

                                        <div className='w-20 d-flex'>
                                            <img
                                                src={spree_coin}
                                                alt="wallet_icon"
                                                className="img-fluid sm-logo mt-1"
                                            />
                                            <span className='leader-name'>{user?.total_earning}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </InfiniteScroll>

                </div>
                <AppFooter />
                {/* {items.map((user) => (
                    <img src={user.url} height="100px" width="200px" />
                ))} */}
            </div>

        </>
    );
}


const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(GlobalLeaderBoard);