import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import spree_coin from './../../images/icons/spreecoin.svg';
import { useHistory } from "react-router-dom";
import back_icon from './../../images/icons/back.svg';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'

function Withdraw(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [walletData, setWalletData] = useState([]);
    const [coins, setCoins] = useState('')
    const [checkedOne, setCheckedOne] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(true)
    const [modalCenteredKK, setModalCenteredKK] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    var axios = require('axios');
    let history = useHistory();

    useEffect(() => {

        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/withdraw-amount-details',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };

        axios(config)
            .then(function (response) {
                let walletCarouselDetail = response.data.original_data
                setWalletData(walletCarouselDetail)
                console.log(walletCarouselDetail.bank_details.kyc_type, 'test kyc type data');
                if (walletCarouselDetail.bank_details.kyc_type === 'UPI') {
                    setShowBankDetails(false)
                } else {
                    setShowBankDetails(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const withdrawMoney = () => {
        var data = ''

        var config = {
            method: 'post',
            url: `https://api.bullspree.com/api/v1/withdraw-amount-request?amount=${coins}`,
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.success) {
                    props.history.push('/page-profile');
                }
                else {
                    setErrorMsg(response.data.message)
                    setModalCenteredKK(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const updateOne = () => {
        setCheckedOne((prev) => !prev)
        if (checkedOne) {
            setCoins(0)
        }
        else {
            setCoins(walletData?.user_withdrawable_coins?.toFixed())
        }
    }


    return (
        <>
            <Modal className="fade bottom-modal" show={modalCenteredKK}>
                <Modal.Body>
                    <p>{errorMsg}</p>
                </Modal.Body>
                <Modal.Footer className='justify-content-center modal-foot-pad'>
                    <Button
                        onClick={() => setModalCenteredKK(false)}
                        className="tds-modal-later-btn wallet-btn"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='top-head-logo mb-25'>
                <div>
                    <img
                        src={back_icon}
                        alt="back_icon"
                        onClick={() => history.goBack()}
                        className="back-icon"
                    />
                </div>
                <div>
                    <h4 className='m-0 noti-head'>Withdraw</h4>
                </div>
            </div>
            <div style={{ height: "90%" }} className='profile-container overflow-auto'>
                <div style={{ minHeight: "100%", position: "relative" }}>

                    <div className='d-flex justify-content-between w-100 transact-p'>
                        <div>
                            <img
                                src={spree_coin}
                                alt="wallet_icon"
                            />
                            <span className='withdrwable-coins'>{(walletData?.user_withdrawable_coins?.toFixed(2))}</span>
                        </div>
                    </div>
                    <div className='d-flex adjust-wid-box'>
                        <div className='col-md-5'>
                            <div>
                                <label className='withdraw-input-label'>Enter Spree Coins</label>
                                <img
                                    src={spree_coin}
                                    alt="wallet_icon"
                                    className="smallest-spree position-absolute"
                                />
                                <input onChange={(e) => setCoins(e.target.value)} value={coins} className='withdraw-input position-relative'></input>
                            </div>

                        </div>
                        <div className='col-md-2'>
                            <span className='span-withdraw-block'>=</span>
                        </div>
                        <div className='col-md-5'>
                            <div>
                                <label className='withdraw-input-label'>10 spree cois = 1 Rupee</label>
                                <input value={coins / 10} className='withdraw-input'></input>
                            </div>
                        </div>
                    </div>
                    <div className='withdraw-checkbox-wrapper'>
                        <input type="checkbox" name="one" checked={checkedOne} onChange={updateOne} />
                        <label>Withdraw All</label>
                    </div>
                    <div className='withdraw-account-detail'>
                        {showBankDetails ?
                            <Link to='/account-verification'>
                                <div className='withdraw-bank-detail'>
                                    <div className='d-flex'>
                                        <div>
                                            <img
                                                src={walletData?.bank_logo}
                                                alt="wallet_icon"
                                                className='logo-img'
                                            />
                                        </div>
                                        <div>
                                            <span className='d-block'>{walletData?.bank_details?.bank_name}</span>
                                            <span className='d-block'>{walletData?.bank_details?.bank_account_number}</span>
                                        </div>
                                    </div>

                                    <div><span>{'>'}</span></div>
                                </div>
                            </Link>
                            :
                            <Link to='/account-verification'>
                                <div className='withdraw-bank-detail'>
                                    <div className='d-flex'>
                                        <div>
                                            <img
                                                src={walletData?.bank_logo}
                                                alt="wallet_icon"
                                                className='logo-img'
                                            />
                                        </div>
                                        <div>
                                            <span className='d-block'>{walletData?.bank_details?.vpa_address}</span>
                                        </div>
                                    </div>

                                    <div><span>{'>'}</span></div>
                                </div>
                            </Link>
                        }

                    </div>
                    <div>
                        <button
                            onClick={withdrawMoney}
                            className="select-stock-btn"
                        >
                            Withdraw Money
                        </button>
                    </div>
                </div>

            </div>

        </>
    );
}


const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Withdraw);