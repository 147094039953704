import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import back_icon from './../../images/icons/back.svg';
import timer_icon from './../../images/big/gif.gif';

import completed_trophy_icon from './../../images/icons/trophy.png';
import spree_coin from './../../images/icons/spreecoin.svg';
import { useHistory } from "react-router-dom";

function PriceDetail(props) {
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [contestDetail, setContestDetail] = useState([])
    const [rankDetail, setRankDetail] = useState([])

    let history = useHistory();
    var axios = require('axios');
    const user_team_id = useSelector((state) => state.contest.user_team_id);


    useEffect(() => {

        var config = {
            method: 'get',
            url: `https://api.bullspree.com/api/v1/user-contest-details-v2?team_id=${user_team_id}`,
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }

        };

        axios(config)
            .then(function (response) {
                setContestDetail(response?.data?.original_data?.user_team)
                var config = {
                    method: 'get',
                    url: `https://api.bullspree.com/api/v1/get-contest-pool-ranges?contest_id=${response?.data?.original_data?.user_team?.contests_id}&team_id=${response?.data?.original_data?.user_team?.user_team_id}`,
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    }
                };

                axios(config)
                    .then(function (response) {
                        console.log(response.data, 'rank list data');
                        setRankDetail(response.data.original_data.contests_pool_range)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            })
            .catch(function (error) {
                console.log(error);
            });

    }, [])


    const getTeamName = (teamId)=>{
        let name = "";
        const { user_team_relation } = contestDetail;
        const team = Array.isArray(user_team_relation) ? user_team_relation.find(t => t.id === teamId) : null;

        if (team) {
            name = team.unique_user_team;
        }

        return name;
    }

    return (
        <>
            <div className='wrapper'>
                <div className='inner-wrapper'>
                    <div className='top-head-logo justify-content-between'>
                        <div className='d-flex justify-content-start'>
                            <div>
                                <img
                                    src={back_icon}
                                    alt="back_icon"
                                    onClick={() => history.goBack()}
                                    className="back-icon"
                                />
                            </div>
                            <div>
                                <h4 className='m-0 noti-head'>COMPLETED</h4>
                            </div>
                        </div>
                    </div>
                    <div className='contest-info-wrapper p-0'>
                        <div className='d-flex justify-content-between pmcd-adjust'>
                            <div>
                                <img
                                    src={completed_trophy_icon}
                                    alt="completed_trophy_icon"
                                    className="completed_trophy_icon"
                                />
                                <h5 className='completed-title'>{contestDetail?.contest_relation?.contest_title}</h5>
                            </div>
                            <h6 className='completed-team-name'>{getTeamName(contestDetail?.user_team_id)}</h6>
                        </div>
                        <div className='d-flex justify-content-between pr-3 pl-3 pb-3'>
                            <div>
                                <span className='d-block completed-team-data-heading'>WON</span>
                                <span className='d-block completed-team-data'>
                                    <img
                                        src={spree_coin}
                                        alt="wallet_icon"
                                        className="smallest-spree"
                                    />
                                    {contestDetail?.won_coins}</span>
                            </div>
                            <div>
                                <span className='d-block completed-team-data-heading'>Rank</span>
                                <span className='d-block completed-team-data'> {contestDetail?.my_rank}</span>
                            </div>
                            <div>
                                <span className='d-block completed-team-data-heading'>Spots</span>
                                <span className='d-block completed-team-data'>{contestDetail?.contest_relation?.join_spot}</span>
                            </div>
                            <div>
                                <span className='d-block completed-team-data-heading'>Entry</span>
                                <img
                                        src={spree_coin}
                                        alt="wallet_icon"
                                        className="smallest-spree"
                                    />
                                <span className='d-block completed-team-data'>{contestDetail?.contest_relation?.entry_fees_coins}</span>
                            </div>
                        </div>
                    </div>
                    <div className='tab-bull-page'>
                        <div className='prize-detail-wrapper'>
                            <h3>PRIZE</h3>
                            {Array.isArray(rankDetail) && rankDetail.map(function (d, i) {
                                return (
                                    <div className='d-flex justify-content-between w-100 rank-prize'>
                                        <div className='rank-div'>{d?.start_rank + (d?.start_rank === d?.end_rank ? '' : '-' + d?.end_rank)}</div>
                                        <div className={i<1 ?'price-div' : 'new-class'}>{d?.coins}</div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(PriceDetail);