import { Fragment, React, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import iconic_trophy_icon from "./../../images/icons/Icon ionic-ios-trophy (1).svg";
import trophy_icon from "./../../images/icons/Icon metro-trophy (1).svg";
import guarantee_icon from "./../../images/icons/Group 92799.svg";
import spree_coin from "./../../images/icons/spreecoin.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { selectExchangeTypeAction, selectUserTeamAction } from "../../store/actions/ContestAction";
import { useHistory, Link } from "react-router-dom";

function CompletedContest(props) {
  const [data, setData] = useState([]);
  const [noContest, setNoContest] = useState(true);
  const exchangeID = useSelector(state => state.contest.exchange_type);

  const dispatch = useDispatch();
  let history = useHistory();
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');

  useEffect(() => {
    let data = JSON.stringify({
      page: 1,
      game_type: "LEAGUE",
      contest_type: "COMPLETED",
      user_level: 1,
      exchange_type: exchangeID,
    });

    let config = {
      method: "post",
      url: "https://api.bullspree.com/api/v1/my-games-v2",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        // if (response.data.original_data.contests.pagination.total > 0) {
        setNoContest(response.data.original_data.contests.data.length === 0);
        setData(response.data.original_data.contests.data);
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [authToken, exchangeID]);

  const cardClick = (test) => {
    if (test.contest_relation.contest_status == 5) {
      dispatch(selectUserTeamAction(test.user_team_id))
      history.push("/contest-detail");
    }
    if (test.contest_relation.contest_status == 4) {
      dispatch(selectUserTeamAction(test.user_team_id))
      history.push("/contest-detail-review");
    }
    if (test.contest_relation.contest_status == 6) {
      console.log("cancelled")
    }
  };

  return (
    <>
      {noContest ? (
        <div className="d-flex m-5 height-fix">
          <Link to="page-contest">
            <span className="join-now">Join Now</span>
          </Link>
        </div>
      ) : null}

      {data.map(function (item, i) {
        console.log(item.user_team_id, "test itemssss");
        let startDate = new Date(item.contest_relation.contests_start_time);
        const sdate = startDate.getDate();
        const smonth = startDate.toLocaleString("default", { month: "short" });
        const shours = startDate.getHours();
        const sminutes = startDate.getMinutes();

        let endDate = new Date(item.contest_relation.contests_end_time);
        const edate = endDate.getDate();
        const emonth = endDate.toLocaleString("default", { month: "short" });
        const ehours = endDate.getHours();
        const eminutes = endDate.getMinutes();

        let progressPercentage =
          (item.contest_relation.join_spot /
            item.contest_relation.max_contest_play) *
          50;
        let statusCheck = item.contest_relation.contest_status;

        console.log({ statusCheck });
        return (
          <Fragment key={item.id}>
            <div className="portfolio-div">
              {item.total_portfolio} Portfolios
            </div>
            {statusCheck == 6 ? (
              <div
                onClick={() => cardClick(item)}
                className="contest-card"
              >
                <div className="card-header-1">
                  <span>
                    <img
                      src={iconic_trophy_icon}
                      alt="trophy"
                      className="small-icon"
                    />
                    {item.contest_relation.win_ratio}
                  </span>
                  <span>
                    <img
                      src={guarantee_icon}
                      alt="trophy"
                      className="small-icon"
                    />
                    {item.contest_relation.contest_category_name}
                  </span>
                </div>
                <div className="d-flex mt--2">
                  <div className="border-bottom-gray"></div>
                  <div className="card-header-2">
                    <span>
                      <img src={trophy_icon} alt="trophy" className="mr-2" />
                      {item.contest_relation.total_winning_coins}
                    </span>
                  </div>
                </div>
                <div className="card-content d-flex justify-content-between">
                  {item.contest_relation.contest_image !== null ? (
                    <div>
                      <img
                        src={item.contest_relation.contest_image}
                        alt="trophy"
                      />
                    </div>
                  ) : (
                    <span className="contest-title">
                      {item.contest_relation.contest_title}
                    </span>
                  )}
                  <button className="view-completed-btn-active color-danger-red">
                    Cancelled
                  </button>
                  <div>
                    <button className="view-completed-btn-active">
                      <div>
                        <span>
                          {sdate} {smonth}{" "}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="contest-card-footer mt-1">
                  <span className="cancell-foot">Your refund will be credited within 7 working days</span>
                </div>
              </div>
            ) :
              statusCheck == 4 ? (
                <div
                  onClick={() => cardClick(item)}
                  className="contest-card"
                >
                  <div className="card-header-1">
                    <span>
                      <img
                        src={iconic_trophy_icon}
                        alt="trophy"
                        className="small-icon"
                      />
                      {item.contest_relation.win_ratio}
                    </span>
                    <span>
                      <img
                        src={guarantee_icon}
                        alt="trophy"
                        className="small-icon"
                      />
                      {item.contest_relation.contest_category_name}
                    </span>
                  </div>
                  <div className="d-flex mt--2">
                    <div className="border-bottom-gray"></div>
                    <div className="card-header-2">
                      <span>
                        <img src={trophy_icon} alt="trophy" className="mr-2" />
                        {item.contest_relation.total_winning_coins}
                      </span>
                    </div>
                  </div>
                  <div className="card-content d-flex justify-content-between">
                    {item.contest_relation.contest_image !== null ? (
                      <div>
                        <img
                          src={item.contest_relation.contest_image}
                          alt="trophy"
                        />
                      </div>
                    ) : (
                      <span className="contest-title">
                        {item.contest_relation.contest_title}
                      </span>
                    )}
                    <button className="view-completed-btn-active color-danger-red">
                      IN Review
                    </button>
                    <div>
                      <button className="view-completed-btn-active">
                        <div>
                          <span>
                            {sdate} {smonth}{" "}
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="contest-card-footer mt-1"></div>
                </div>
              ) :
                (
                  <div
                    onClick={() => cardClick(item)}
                    className="contest-card"
                  >
                    <div className="card-header-1">
                      <span>
                        <img
                          src={iconic_trophy_icon}
                          alt="trophy"
                          className="small-icon"
                        />
                        {item.contest_relation.win_ratio}
                      </span>
                      <span>
                        <img
                          src={guarantee_icon}
                          alt="trophy"
                          className="small-icon"
                        />
                        {item.contest_relation.contest_category_name}
                      </span>
                    </div>
                    <div className="d-flex mt--2">
                      <div className="border-bottom-gray"></div>
                      <div className="card-header-2">
                        <span>
                          <img src={trophy_icon} alt="trophy" className="mr-2" />
                          {item.contest_relation.total_winning_coins}
                        </span>
                      </div>
                    </div>
                    <div className="card-content d-flex justify-content-between">
                      {item.contest_relation.contest_image !== null ? (
                        <div>
                          <img
                            src={item.contest_relation.contest_image}
                            alt="trophy"
                          />
                        </div>
                      ) : (
                        <span className="contest-title">
                          {item.contest_relation.contest_title}
                        </span>
                      )}
                      <div>
                        <button className="view-completed-btn-active">
                          View Details
                        </button>
                      </div>
                    </div>
                    <div className="contest-card-footer mt-1">
                      <div>
                        <button className="play-btn w-100 completed-btn justify-content-between">
                          <div>
                            <span>Total Winnings :</span>
                          </div>
                          <div>
                            <img src={spree_coin} alt="trophy" />

                            <span>{item.won_coins}</span>
                          </div>
                        </button>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 className="completed-rank">Your Best Rank :</h6>
                          </div>
                          <div>
                            <h6 className="completed-rank">{item.my_rank}</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 className="completed-rank font-w500">Spots :</h6>
                          </div>
                          <div>
                            <h6 className="completed-rank font-w500">
                              {item.contest_relation.join_spot}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-content-footer mt-3 d-flex justify-content-between">
                        <div>
                          <span>
                            starts : {sdate} {smonth} | {shours}:{sminutes}
                          </span>
                        </div>

                        <div>
                          <span>
                            ends : {edate} {emonth} | {ehours}:{eminutes}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

          </Fragment>
        );
      })}
      <div>
        <Dropdown className="exchange-dropdown">
          <Dropdown.Toggle
            className="exchange-drop-btn"
            id="dropdown-basic"
          >
           {
            (exchangeID===1)?"IND": (exchangeID===2)?"US": (exchangeID===3)?"Crypto":null
           }
          </Dropdown.Toggle>

          <Dropdown.Menu className="exchange-drop-menu">
            <Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(1))}
              className="exchange-drop-item"
            >
              IND
            </Dropdown.Item>
            {/*<Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(3))}
              className="exchange-drop-item"
            >
              Crypto
            </Dropdown.Item>*/}
            <Dropdown.Item
              onClick={() => dispatch(selectExchangeTypeAction(2))}
              className="exchange-drop-item"
            >
              US Stocks
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(CompletedContest);
