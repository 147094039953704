import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {

    //axios call

    const postData = {
        email,
        password,
        returnSecureToken: true,
    };

    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function continueClick(phone, otp, build_version, device_name, name, referral) {
    var updatedPhone = `91${phone}`
    const postData = {
        full_name: name,
        phone: updatedPhone,
        otp: otp,
        signup_from: "WEBSITE",
        signup_type: "GOOGLE",
        build_version: build_version,
        device_name: device_name,
        referral_code: referral ? referral : ''
    };
    return axios.post(`https://api.bullspree.com/api/v1/verify-otp-new`, postData);
}

export function updateUserLevel(auth) {
    let body ={
        is_beginner : 1
    }
    var config = {
        method: 'post',
        url: 'https://api.bullspree.com/api/v1/choose-user-level',
        headers: {
            'Authorization': `Bearer ${auth}`,
        },
        data: body,
    };

    return axios(config)
    // const postData = {
    //     is_beginner : 1
    // }
    // return axios.post(`https://api.bullspree.com/api/v1/choose-user-level`,postData);
}

export function checkReferral(referral) {
    return axios.get(`https://api.bullspree.com/api/v1/verify-referral-code?referral_code=${referral}`);
}


export function getImages() {
    var authToken = JSON.parse(localStorage.getItem("authDetails"));

    var config = {
        method: 'get',
        url: 'https://api.bullspree.com/api/v1/get-carousels?exchange_type=0&view_banner_on=0',
        headers: {
            'Authorization': `Bearer ${authToken}`,
        }
    };

    return axios(config)
}

export function generateOtp(phone) {
    const postData = {
        phone: phone
    }
    return axios.post(`https://api.bullspree.com/api/v1/generate-otp`, postData);
}
export function getUserDetails(auth) {
    var config = {
        method: 'get',
        url: 'https://api.bullspree.com/api/v1/get-user-details',
        headers: {
            'Authorization': `Bearer ${auth}`,
        }
    };

    return axios(config)
}

export function loginClick(phone) {
    return axios.get(`https://api.bullspree.com/api/v1/get-user-by-phone?phone=${'91' + phone}`);
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function saveAuthInLocalStorage(authDetails) {
    localStorage.setItem('authDetails', JSON.stringify(authDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export const getUserWallet = async (authToken) => {
    const config = {
        method: 'get',
        url: 'https://api.bullspree.com/api/v1/get-user-wallets?page=1&wallet_transaction_type=',
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    };

    const response = await axios(config);
    return response;
}

export const updateUserProfile = async (authToken, data) => {
  const config = {
    method: "put",
    url: `https://api.bullspree.com/api/v1/update-user-profile?email=${data.email}&full_name=${data.full_name}`,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const response = await axios(config);
  return response;
};

export const updateUserProfilePicture = async (authToken, data) => {
    const config = {
      method: "put",
      url: `https://api.bullspree.com/api/v1/update-user-profile-image`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data,
    };
  
    const response = await axios(config);
    return response;
  };