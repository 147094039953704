import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import back_icon from './../../images/icons/back.svg';
import arrow_icon from './../../images/icons/arrow.svg'
import lost_icon from './../../images/icons/vuesax-linear-info-circle.svg'
import bonus_icon from './../../images/icons/vuesax-linear-add-circle.svg'
import cancel_icon from './../../images/icons/vuesax-linear-minus-cirlce.svg'
import won_icon from './../../images/icons/vuesax-linear-tick-circle.svg'
import { useHistory } from "react-router-dom";
import { selectUserTeamAction } from '../../store/actions/ContestAction';

function Notification(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [notifiedData, setNotifiedData] = useState([])
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('page', '2');

        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-user-notifications?page=1',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                let finalData = response.data.original_data.notifications.data
                setNotifiedData(finalData)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleNotificationRedirect = (notification)=>{
        const { custom_attributes, notification_type } = notification;
        const { team_id } = custom_attributes;

        switch (notification_type) {
            case "CONTEST_WON":
            case "CONTEST_LOST":
                dispatch(selectUserTeamAction(team_id));
                history.push('/contest-detail');
                break;

            default:
                history.push('/wallet-page');
                break;
        }

    }

    return (
        <>
            <div className='wrapper'>
                <div className='top-head-logo mb-25'>
                    <div>
                        <img
                            src={back_icon}
                            alt="back_icon"
                            onClick={() => history.goBack()}
                            className="back-icon"
                        />
                    </div>
                    <div>
                        <h4 className='m-0 noti-head'>Notification</h4>
                    </div>
                </div>
                {Array.isArray(notifiedData) && notifiedData.map(function (item, i) {
                    return (
                        <div onClick={() => handleNotificationRedirect(item)} className='noti-tab'>
                            <div className='d-flex justify-content-start' >
                                <div>
                                    {item.notification_type === "CONTEST_WON" ?
                                        <img
                                            src={won_icon}
                                            alt="back_icon"
                                            className="back-icon"
                                        />
                                   : null }
                                   {item.notification_type === "CONTEST_LOST" ?
                                        <img
                                            src={lost_icon}
                                            alt="back_icon"
                                            className="back-icon"
                                        />
                                   : null }
                                   {item.notification_type === "CONTEST_CANCEL" ?
                                        <img
                                            src={cancel_icon}
                                            alt="back_icon"
                                            className="back-icon"
                                        />
                                   : null }
                                   {item.notification_type === "STRICK_BONUS" ?
                                        <img
                                            src={bonus_icon}
                                            alt="back_icon"
                                            className="back-icon"
                                        />
                                   : null }
                                     
                                   
                                </div>
                                <div className='d-flex justify-content-between w-100 ml-2'>
                                    <p className='mb-0 noti-topic'>{item.title}</p>
                                    <img
                                        src={arrow_icon}
                                        alt="back_icon"
                                        className="back-icon"
                                        onClick={() => handleNotificationRedirect(item)}
                                    />
                                </div>

                            </div>
                            <div className='noti-detail-rap'>
                                <p>{item.message}</p>
                                <p className='text-right'>{item.updated_at}</p>
                            </div>
                        </div>
                    )
                })}


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Notification);