import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import back_icon from './../../images/icons/back.svg';
import { useHistory } from "react-router-dom";



//
function Guidelines(props) {
    const [Guidelines, setGuidelines] = useState('');
    const getGuidelinesList = () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/cms-pages?page_code=GUIDELINES',
        };

        axios(config)
            .then(function (response) {
                const GuidelinesResult = response.data.original_data.pages.page_content;
                setGuidelines(GuidelinesResult);
            })
            .catch(function (error) {
                console.log(error);
            });

    };
    useEffect(() => {
        getGuidelinesList()
    }, [])
    let history = useHistory();

    return (<>
    <div className='top-head-logo mb-25'>
            <div>
                <img
                    src={back_icon}
                    alt="back_icon"
                    onClick={() => history.goBack()}
                    className="back-icon"
                />
            </div>
            <div>
                <h4 className='m-0 noti-head'>GUIDELINES</h4>
            </div>
        </div>

        
        <div style={{height:"90%"}} className='profile-container overflow-auto'>
        <div className='html-view-div' dangerouslySetInnerHTML={{__html: Guidelines}} />
        </div>
    </>

    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Guidelines);