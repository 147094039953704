import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import { persistStore } from "redux-persist";
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import persistReducer from 'redux-persist/es/persistReducer';
import sessionStorage from 'redux-persist/es/storage/session';
import ContestReducer from './reducers/ContestReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: persistReducer({ key: "auth", storage: sessionStorage }, AuthReducer),
  contest: persistReducer({ key: "contest", storage: sessionStorage, blacklist: ['exchange_type'] }, ContestReducer),
});

export const store = createStore(reducers, composeEnhancers(middleware));

export const persistor = persistStore(store);
