import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import trophy_icon from './../../images/icons/Mask Group 373.png';
import spree_coin from './../../images/icons/spreecoin.svg';
import lock_icon from './../../images/icons/Mask Group 374.png';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'


//
function TransactionDetail(props) {
    const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [walletData, setWalletData] = useState()
    const [showDrop, setShowDrop] = useState(false)
    const [hideDrop, setHideDrop] = useState(true)

    useEffect(() => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: 'https://api.bullspree.com/api/v1/get-user-wallets?page=1&bonus=false',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.original_data.wallet)
                setWalletData(response.data.original_data.wallet)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);


    return (
        <>
            <div className='h-40'>
                <h4 className='wallet-heading'>Transaction Details</h4>
                <div className='center-div'>
                    <div>
                    <h6 className='text-light text-center'>You Added</h6>
                    <img
                        src={spree_coin}
                        alt="wallet_icon"
                        className="bg-logo"
                    />
                    <span className='td-wallet-price'>{walletData?.total_winning_coins}</span>
                    </div>
                </div>
            </div>
            <div className='profile-container h-60 p-2 mt-3'>
                <div>
                    <h4>Phone Pay - </h4>
                    <span></span>
                </div>
            </div>


        </>

    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(TransactionDetail);