import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import spree_coin from './../../images/icons/spreecoin.svg';
import i_icon from './../../images/icons/i icon.svg';

import { Card, Modal, Carousel, Button } from 'react-bootstrap'
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import back_icon from './../../images/icons/back arrow.svg';


//

function TaxDetail(props) {
    const [modalCentered, setModalCentered] = useState(false);
    const [modalCenteredPP, setModalCenteredPP] = useState(false);
    const [modalCenteredCC, setModalCenteredCC] = useState(false);

    const [showViewAll, setShowViewAll] = useState(true)
    const [hideViewAll, setHideViewAll] = useState(false)
  const authToken = useSelector(state => state.auth?.auth?.idToken ?? '');
    const [slides, setSlides] = useState([]);
    const [walletData, setWalletData] = useState([]);
    const [tdswalletData, setTdsWalletData] = useState([]);

    let history = useHistory();

    useEffect(() => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: ' https://api.bullspree.com/api/v1/get-user-wallet-tds-details',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.original_data.tds_carousol, 'test tds data');
                let dataFinal = (response.data.original_data);
                setTdsWalletData(dataFinal)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const handleWallet = () => {
        console.log("i was clicked wallet")
        if (walletData?.total_user_withdrawable_coins >= 1000) {
            props.history.push('/withdraw-page')
        }

        else {
            setModalCenteredPP(true)
        }
    }
    const handelViewAll = () => {
        if(tdswalletData?.tds_deducted_history.length > 0){
        setShowViewAll(false)
        setHideViewAll(true)
        }
    }
    const handleHideView = () => {
        setShowViewAll(true)
        setHideViewAll(false)
    }
    return (
        <>
            <div className='home-page-container'>
                <div className='top-head-logo mt-0 bg-white posi-fix-tds'>
                    <div>
                        <img
                            src={back_icon}
                            alt="back_icon"
                            onClick={() => history.goBack()}
                            className="back-icon mr-2"
                        />
                    </div>
                    <div>
                        <h4 className='m-0 noti-head text-bull'>Tax Details</h4>
                    </div>
                </div>
                <div className='home-page-content h-auto po-fi-pad'>
                    <div className='tds-card-wrapper'>
                        <div className='wallet-tds-detail-wrap'>
                            <div className='d-flex justify-content-between pb-13'>
                                <span>Total Winnings</span>
                                <span className='text-dark-tax'>{tdswalletData?.total_winning_coins}</span>
                            </div>
                            <div className='d-flex justify-content-between pb-13'>
                                <span>Investments from winnings</span>
                                <span className='text-dark-tax'>{tdswalletData?.total_investment}</span>
                            </div>
                            <div className='line-div'></div>
                            <div className='d-flex justify-content-between pb-13'>
                                <span>Withdrawable amount</span>
                                <span className='text-dark-tax'>{tdswalletData?.total_withdrawble_coins}</span>
                            </div>
                            <div className='d-flex justify-content-between pb-13'>
                                <span onClick={() => setModalCenteredCC(true) } className='bull-text'>Cumulative TDS</span>
                                <span className='bull-text'>{tdswalletData?.cumulative_tds_coins}</span>
                            </div>
                            <div className='d-flex justify-content-between pb-13'>
                                <span>Already withdrawn</span>
                                <span className='text-dark-tax'>{tdswalletData?.already_withdraw}</span>
                            </div>
                        </div>
                        <div className='tds-card-footer'>
                            <div className='d-flex justify-content-between pb-13'>
                                <span className='bull-text font-w500'>Final withdrawable amount</span>
                                <span className='bull-text font-w500'>{tdswalletData?.final_withdrawble_coins}</span>
                            </div>
                            <div className='mt-0'>
                                <div >
                                    <button onClick={() => setModalCentered(true)} className='wallet-btn w-100 f-15 mt-3'>Withdraw now</button>
                                </div>

                                <Modal className="fade bottom-modal" show={modalCentered}>
                                    <Modal.Body>
                                        <p>We encourage you to not withdraw frequently and have a 30% extra amount to play contests</p>
                                    </Modal.Body>
                                    <Modal.Footer className='justify-content-center modal-foot-pad'>
                                        <Link to='withdraw-page'>
                                            <button onClick={() => handleWallet()} className='tds-modal-now-btn'>
                                                Withdraw Now
                                            </button>
                                        </Link>
                                        <Button
                                            onClick={() => setModalCentered(false)}
                                            className="tds-modal-later-btn wallet-btn"
                                        >
                                            Withdraw Later
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal className="fade center-modal" show={modalCenteredCC}>
                                    <Modal.Body>
                                        <div className='tds-mod-wrapper'>
                                            <h4>Cumulative TDS</h4>
                                            <div>
                                                <span className='pb-3 d-block'>= Net winnings X TDS rate/100</span>
                                                <span  className='pb-3 d-block'>= 3000 X 30/100</span>
                                                <span  className='pb-3 d-block'>= 900</span>
                                            </div>
                                        </div>
                                        <div className='tds-mod-wrapper'>
                                            <h4>Net winnings</h4>
                                            <div>
                                                <span  className='pb-3 d-block'>= Total winnings - Total investment</span>
                                                <span  className='pb-3 d-block'>= 4000 - 1000</span>
                                                <span  className='pb-3 d-block'>= 4000</span>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>

                    <div className='tds-card-wrapper'>
                        {showViewAll ?
                            <>
                                <div className='wallet-tds-detail-wrap bg-wallet-light'>
                                    <div className='d-flex justify-content-between'>
                                        <span className='bull-text-med'>Tax deducted : {tdswalletData?.total_deducted_tds}</span>
                                        <span className='bull-text-med'>Withdrawn : {tdswalletData?.total_withdraw}</span>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={() => handelViewAll()} className='view-btn-detail'>view all</button>
                                </div>
                            </>
                            : null}
                        {hideViewAll ?
                            <>
                                <div className='wallet-tds-detail-wrap bg-wallet-light p-0'>
                                    <div className='d-flex justify-content-between pad-view-detail'>
                                        <span className='bull-text-med w-25'>History</span>
                                        <span className='bull-text-med ml-5'>Tax deducted</span>
                                        <span className='bull-text-med'>Withdrawn</span>
                                    </div>
                                    <div className='bg-white scroll-height-adj'>
                                        <div className='d-flex justify-content-between pad-view-detail'>


                                            <ul className='w-100'>
                                                {Array.isArray(tdswalletData?.tds_deducted_history) && tdswalletData?.tds_deducted_history
                                                    .map(function (item, i) {
                                                        return (
                                                            <>
                                                                <li className='d-flex justify-content-between w-100'><span className='align-text' >{item?.tds_deduction_date}</span><span className='text-end' >{item?.tds_amount}</span><span className='text-end'>{item?.amount}</span></li>

                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>


                                        </div>
                                        {/* <div className='d-flex justify-content-end pad-view-detail'>
                                            <span className='text-align-right'>Balance: 3100</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className=''>
                                    <button onClick={() => handleHideView()} className='view-btn-detail justify-content-between d-flex'>

                                        <span>Tax deducted : {tdswalletData?.total_deducted_tds}</span>
                                        <span>Tax deducted : {tdswalletData?.total_withdraw}</span>
                                    </button>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(TaxDetail);